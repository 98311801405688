import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://soldrena.com/api/auth/login', {
                username,
                password,   
            });
    
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('role', response.data.role);
    
            setMessage('Giriş başarılı!');
    
            if (response.data.role === 'Admin') {
                navigate('/admin-dashboard');
            } else if (response.data.role === 'Manager') {
                navigate('/manager-dashboard');
            } else if (response.data.role === 'Programmer') {
                navigate('/programmer-dashboard');
            } else if (response.data.role === 'Operator') {
                navigate('/qr-scanner');
            } else {
                navigate('/');
            }
        } catch (error) {
            setMessage('Giriş başarısız. Lütfen bilgilerinizi kontrol edip tekrar deneyin.');
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="w-full max-w-xs">
                <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleLogin}>
                    <h2 className="text-2xl font-bold mb-6 text-center">Giriş</h2>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            Kullanıcı Adı
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="username"
                            type="text"
                            placeholder="Kullanıcı Adı"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Şifre
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type="password"
                            placeholder="Şifre"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Giriş Yap
                        </button>
                    </div>
                    {message && <p className="text-red-500 text-xs italic mt-4">{message}</p>}
                </form>
            </div>
        </div>
    );
}

export default Login;
