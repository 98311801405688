import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProgrammerDashboard = () => {
    const [operators, setOperators] = useState([]);
    const [projects, setProjects] = useState([]);
    const [cncCodes, setCncCodes] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [camCode, setCamCode] = useState('');
    const [selectedOperator, setSelectedOperator] = useState('');
    const [selectedCncCode, setSelectedCncCode] = useState('');
    const [price, setPrice] = useState('');
    const [partCount, setPartCount] = useState(1);
    const [message, setMessage] = useState({ text: '', type: '' });
    const [loading, setLoading] = useState(false);
    const [traceabilityData, setTraceabilityData] = useState([]);
    const [editingRowId, setEditingRowId] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showFilters, setShowFilters] = useState(false);

    const fetchTraceabilityData = async () => {
        try {
            const response = await axios.get('https://soldrena.com/api/traceability', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setTraceabilityData(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Ürün Verileri alınırken hata oluştu:', error);
            setTraceabilityData([]);
        }
    };

    const userRole = localStorage.getItem('role');
    const canSeePrice = userRole === 'Admin' || userRole === 'Manager';

    useEffect(() => {
        fetchProjects();
        fetchOperators();
        fetchCncCodes();
        fetchTraceabilityData();  // Fetch traceability data
    }, []);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchProjects = async () => {
        try {
            const response = await axios.get('https://soldrena.com/api/traceability/projects', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setProjects(response.data);
        } catch (error) {
            console.error('Projeler alınırken hata oluştu:', error);
        }
    };

    const fetchOperators = async () => {
        try {
            const response = await axios.get('https://soldrena.com/api/traceability/operators', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setOperators(response.data);
        } catch (error) {
            console.error('Operatörler alınırken hata oluştu:', error);
        }
    };

    const fetchCncCodes = async () => {
        try {
            const response = await axios.get('https://soldrena.com/api/traceability/cnc-codes', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setCncCodes(response.data);
        } catch (error) {
            console.error('CNC kodları alınırken hata oluştu:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedProject || !camCode || !selectedOperator || !selectedCncCode) {
            setMessage({ text: 'Lütfen tüm gerekli alanları doldurun.', type: 'error' });
            return;
        }
        
        setLoading(true);
        try {
            const response = await axios.post(
                'https://soldrena.com/api/traceability/insert-data',
                {
                    data: [
                        {
                            partNumber: camCode,
                            miktar: partCount,
                            projectId: selectedProject,  // Corrected name
                            operatorId: selectedOperator,
                            cncId: selectedCncCode,
                            price: userRole === 'Admin' || userRole === 'Manager' ? price : undefined
                        }
                    ]
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
    
            setMessage({ text: response.data.message, type: 'success' });
            // Reset form
            setSelectedProject('');
            setCamCode('');
            setSelectedOperator('');
            setSelectedCncCode('');
            setPrice('');
            setPartCount(1);
        } catch (error) {
            setMessage({ text: 'Veri gönderilemedi.', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (id, field, value) => {
        setTraceabilityData(traceabilityData.map(data =>
            data.id === id ? { ...data, [field]: value } : data
        ));
    };

    const toggleEdit = (id) => {
        setEditingRowId(id);
    };

    const cancelEdit = () => {
        setEditingRowId(null);
    };

    const handleUpdate = async (id, updatedData) => {
        setLoading(true);
        try {
            const payload = {
                ...updatedData,
                part_count: updatedData.part_count || 1,
            };

            await axios.put(`https://soldrena.com/api/traceability/update/${id}`, payload, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });

            setMessage({ text: 'Veri başarıyla güncellendi', type: 'success' });
            setEditingRowId(null); // Düzenleme modundan çık
            fetchTraceabilityData(); // Verileri yenile
        } catch (error) {
            console.error('Veri güncellenirken hata oluştu:', error);
            setMessage({ text: 'Veri güncellenemedi', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            await axios.delete(`https://soldrena.com/api/traceability/delete/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessage({ text: 'Veri başarıyla silindi', type: 'success' });
            fetchTraceabilityData(); // Verileri yenile
        } catch (error) {
            console.error('Veri silinirken hata oluştu:', error);
            setMessage({ text: 'Veri silinemedi', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const renderMobileCard = (data) => (
        <div key={data.id} className="bg-[#2f2f2f] p-4 mb-4 rounded-lg">
            {editingRowId === data.id ? (
                <>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-1">Project:</label>
                        <select
                            value={data.project_id}
                            onChange={(e) => handleInputChange(data.id, 'project_id', e.target.value)}
                            className="w-full p-2 bg-gray-900 text-gray-200 rounded border border-gray-600"
                        >
                            {projects.map((project) => (
                                <option key={project.id} value={project.project_id}>
                                    {project.project_id} - {project.project_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-1">CAM Kodu:</label>
                        <input
                            type="text"
                            value={data.cam_code}
                            onChange={(e) => handleInputChange(data.id, 'cam_code', e.target.value)}
                            className="w-full p-2 bg-gray-900 text-gray-200 rounded border border-gray-600"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-1">Operator:</label>
                        <select
                            value={data.operator_id}
                            onChange={(e) => handleInputChange(data.id, 'operator_id', e.target.value)}
                            className="w-full p-2 bg-gray-900 text-gray-200 rounded border border-gray-600"
                        >
                            {operators.map((operator) => (
                                <option key={operator.id} value={operator.id}>
                                    {operator.full_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-1">CNC Code:</label>
                        <select
                            value={data.cnc_id}
                            onChange={(e) => handleInputChange(data.id, 'cnc_id', e.target.value)}
                            className="w-full p-2 bg-gray-900 text-gray-200 rounded border border-gray-600"
                        >
                            {cncCodes.map((cnc) => (
                                <option key={cnc.id} value={cnc.id}>
                                    {cnc.cnc_code}
                                </option>
                            ))}
                        </select>
                    </div>
                    {canSeePrice && (
                        <div className="mb-2">
                            <label className="block text-sm font-bold mb-1">Price:</label>
                            <div className="flex items-center">
                                <span className="mr-2">₺</span>
                                <input
                                    type="number"
                                    value={data.price}
                                    onChange={(e) => handleInputChange(data.id, 'price', e.target.value)}
                                    className="w-full p-2 bg-gray-900 text-gray-200 rounded border border-gray-600"
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <p><strong>Project:</strong> {data.project_code}</p>
                    <p><strong>CAM Kodu:</strong> {data.cam_code}</p>
                    <p><strong>Operator:</strong> {data.operator_name}</p>
                    <p><strong>CNC Code:</strong> {data.cnc_code}</p>
                    {canSeePrice && <p><strong>Price:</strong> ₺{data.price}</p>}
                </>
            )}
            <div className="mt-2">
                {editingRowId === data.id ? (
                    <>
                        <button
                            onClick={() => handleUpdate(data.id, data)}
                            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 w-full mb-2"
                            disabled={loading}
                        >
                            Kaydet
                        </button>
                        <button
                            onClick={cancelEdit}
                            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 w-full"
                        >
                            İptal
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            onClick={() => toggleEdit(data.id)}
                            className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 w-full mb-2"
                        >
                            Düzenle
                        </button>
                        {userRole === 'Admin' && (
                            <button
                                onClick={() => handleDelete(data.id)}
                                className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 w-full"
                            >
                                Sil
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );

    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200">
            <div className="container mx-auto p-4">
                <h2 className="text-3xl font-semibold mb-6 text-gray-100">Ürün Paneli</h2>
                {message.text && (
                    <p className={`mb-4 ${message.type === 'error' ? 'text-red-500' : 'text-green-500'}`}>
                        {message.text}
                    </p>
                )}
                <form onSubmit={handleSubmit} className="bg-[#212121] p-6 rounded shadow-md">
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2" htmlFor="project">
                            Proje Seç
                        </label>
                        <select
                            id="project"
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            value={selectedProject}
                            onChange={(e) => setSelectedProject(e.target.value)} // Select integer ID
                            required
                        >
                            <option value="" disabled>Bir proje seçin</option>
                            {projects.map((project) => (
                                <option key={project.id} value={project.id}> {/* Use project.id */}
                                    {project.project_id} - {project.project_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2" htmlFor="cam_code">
                            CAM Kodu
                        </label>
                        <input
                            id="cam_code"
                            type="text"
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            value={camCode}
                            onChange={(e) => setCamCode(e.target.value)}
                            placeholder="CAM kodunu girin"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2" htmlFor="operator">
                            Operatör Seç
                        </label>
                        <select
                            id="operator"
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            value={selectedOperator}
                            onChange={(e) => setSelectedOperator(e.target.value)}
                            required
                        >
                            <option value="" disabled>Bir operatör seçin</option>
                            {operators.map((operator) => (
                                <option key={operator.id} value={operator.id}>
                                    {operator.full_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2" htmlFor="cnc_code">
                            CNC Kodu Seç
                        </label>
                        <select
                            id="cnc_code"
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            value={selectedCncCode}
                            onChange={(e) => setSelectedCncCode(e.target.value)}
                            required
                        >
                            <option value="" disabled>Bir CNC kodu seçin</option>
                            {cncCodes.map((cnc) => (
                                <option key={cnc.id} value={cnc.id}>
                                    {cnc.cnc_code}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2" htmlFor="part_count">
                            Parça Sayısı
                        </label>
                        <input
                            id="part_count"
                            type="number"
                            min="1"
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            value={partCount}
                            onChange={(e) => setPartCount(e.target.value)}
                            required
                        />
                    </div>
                    {(userRole === 'Admin' || userRole === 'Manager') && (
                        <div className="mb-4">
                            <label className="block text-gray-400 text-sm font-semibold mb-2" htmlFor="price">
                                Fiyat
                            </label>
                            <input
                                id="price"
                                type="number"
                                step="0.01"
                                className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                placeholder="Fiyatı girin"
                            />
                        </div>
                    )}
                    <button
                        type="submit"
                        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200"
                        disabled={loading}
                    >
                        {loading ? 'Gönderiliyor...' : 'Gönder'}
                    </button>
                </form>

                {/* Table Section */}
                <h2 className="text-3xl font-semibold mb-6 text-gray-100 mt-8">Ürün Verileri</h2>
                {isMobile ? (
                    <>
                        <button
                            onClick={() => setShowFilters(!showFilters)}
                            className="w-full mb-4 bg-blue-600 text-white py-2 px-4 rounded"
                        >
                            {showFilters ? 'Filtreleri Gizle' : 'Filtreleri Göster'}
                        </button>
                        {showFilters && (
                            <div className="mb-4">
                                {/* Add filter inputs here if needed */}
                            </div>
                        )}
                        {traceabilityData.map(renderMobileCard)}
                    </>
                ) : (
                    <table className="min-w-full bg-[#2f2f2f] shadow-md rounded overflow-hidden">
                        <thead className="bg-[#2f2f2f]">
                            <tr>
                                <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Proje</th>
                                <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">CAM Kodu</th>
                                <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Operatör</th>
                                <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">CNC Kodu</th>
                                {canSeePrice && (
                                    <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Fiyat</th>
                                )}
                                <th className="py-3 px-4 border-b border-gray-600 text-center text-gray-400 font-semibold">İşlemler</th>
                            </tr>
                        </thead>
                        <tbody>
                            {traceabilityData.map((data) => (
                                <tr key={data.id} className="hover:bg-gray-900 text-gray-200 transition duration-200">
                                    <td className="py-3 px-4 border-b border-gray-600">
                                        {editingRowId === data.id ? (
                                            <select
                                                value={data.project_id}
                                                onChange={(e) => handleInputChange(data.id, 'project_id', e.target.value)}
                                                className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            >
                                                {projects.map((project) => (
                                                    <option key={project.id} value={project.project_id}>
                                                        {project.project_id} - {project.project_name}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            data.project_code
                                        )}
                                    </td>
                                    <td className="py-3 px-4 border-b border-gray-600">
                                        {editingRowId === data.id ? (
                                            <input
                                                type="text"
                                                value={data.cam_code}
                                                onChange={(e) => handleInputChange(data.id, 'cam_code', e.target.value)}
                                                className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            />
                                        ) : (
                                            data.cam_code
                                        )}
                                    </td>
                                    <td className="py-3 px-4 border-b border-gray-600">
                                        {editingRowId === data.id ? (
                                            <select
                                                value={data.operator_id}
                                                onChange={(e) => handleInputChange(data.id, 'operator_id', e.target.value)}
                                                className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            >
                                                {operators.map((operator) => (
                                                    <option key={operator.id} value={operator.id}>
                                                        {operator.full_name}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            data.operator_name
                                        )}
                                    </td>
                                    <td className="py-3 px-4 border-b border-gray-600">
                                        {editingRowId === data.id ? (
                                            <select
                                                value={data.cnc_id}
                                                onChange={(e) => handleInputChange(data.id, 'cnc_id', e.target.value)}
                                                className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            >
                                                {cncCodes.map((cnc) => (
                                                    <option key={cnc.id} value={cnc.id}>
                                                        {cnc.cnc_code}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            data.cnc_code
                                        )}
                                    </td>
                                    {canSeePrice && (
                                        <td className="py-3 px-4 border-b border-gray-600">
                                            {editingRowId === data.id ? (
                                                <div className="flex items-center">
                                                    <span>₺</span>
                                                    <input
                                                        type="number"
                                                        value={data.price}
                                                        onChange={(e) => handleInputChange(data.id, 'price', e.target.value)}
                                                        className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                                    />
                                                </div>
                                            ) : (
                                                <span>₺{data.price}</span>
                                            )}
                                        </td>
                                    )}
                                    <td className="py-3 px-4 border-b border-gray-600 text-center">
                                        {editingRowId === data.id ? (
                                            <>
                                                <button
                                                    onClick={() => handleUpdate(data.id, data)}
                                                    className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200"
                                                    disabled={loading}
                                                >
                                                    Kaydet
                                                </button>
                                                <button
                                                    onClick={cancelEdit}
                                                    className="bg-red-600 hover:bg-red-700 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200 ml-2"
                                                >
                                                    İptal
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={() => toggleEdit(data.id)}
                                                    className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200"
                                                >
                                                    Düzenle
                                                </button>
                                                {userRole === 'Admin' && (
                                                    <button
                                                        onClick={() => handleDelete(data.id)}
                                                        className="bg-red-600 hover:bg-red-700 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200 ml-2"
                                                    >
                                                        Sil
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default ProgrammerDashboard;
