import React from 'react';

const Forbidden = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-800 text-white">
            <div className="text-center">
                <h1 className="text-6xl font-bold mb-4">403</h1>
                <p className="text-xl mb-8">Forbidden: You don't have permission to access this page.</p>
                <img src="/images/forbidden.gif" alt="Forbidden" className="mx-auto mb-8" />
                <a href="/" className="text-blue-500 underline">Go Back to Home</a>
            </div>
        </div>
    );
};

export default Forbidden;
