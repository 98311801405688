import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UploadExcelPage = () => {
    const [file, setFile] = useState(null);
    const [projectId, setProjectId] = useState(''); // Integer ID for SQL insertion
    const [projectCode, setProjectCode] = useState(''); // String project_id for Excel analysis
    const [operators, setOperators] = useState([]);
    const [cncCodes, setCncCodes] = useState([]);
    const [projects, setProjects] = useState([]);
    const [data, setData] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState([]);
    const [dragActive, setDragActive] = useState(false);

    // Fetch the available projects, operators, and CNC codes
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const projectRes = await axios.get('https://soldrena.com/api/traceability/projects', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setProjects(projectRes.data);

                const operatorRes = await axios.get('https://soldrena.com/api/traceability/operators', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setOperators(operatorRes.data);

                const cncRes = await axios.get('https://soldrena.com/api/traceability/cnc-codes', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setCncCodes(cncRes.data);
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };

        fetchDropdownData();
    }, []);

    // Handle file change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle project selection and extract projectCode (string) and projectId (int)
    const handleProjectChange = (e) => {
        const selectedProject = projects.find(project => project.id === parseInt(e.target.value));
        if (selectedProject) {
            setProjectId(selectedProject.id); // Integer ID for SQL
            setProjectCode(selectedProject.project_id); // String project_id for Excel analysis
        }
    };
    
    const handleUpload = async () => {
        if (!file || !projectId) {
            setMessage('Please select a file and a project ID.');
            return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('projectId', projectId); // Integer for SQL
        formData.append('projectCode', projectCode); // String for Excel analysis
    
        setLoading(true);
    
        try {
            const response = await axios.post('https://soldrena.com/api/traceability/analyze-excel', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setData(response.data);
            setEditData(response.data);
            setMessage('File processed successfully. You can now review the data.');
        } catch (error) {
            console.error('Error uploading file:', error);
            setMessage('Failed to upload file');
        } finally {
            setLoading(false);
        }
    };
    

    // Handle data edits
    const handleEditChange = (index, field, value) => {
        const newData = [...editData];
        newData[index][field] = value;
        setEditData(newData);
    };

    // Handle data confirmation
    const handleConfirm = async () => {
        try {
            const response = await axios.post(
                'https://soldrena.com/api/traceability/insert-data',
                { data: editData },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            setMessage(response.data.message);
        } catch (error) {
            console.error('Error confirming data:', error);
            setMessage('Failed to insert data');
        }
    };

    // Drag-and-drop logic
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFile(e.dataTransfer.files[0]);
        }
    };

    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200">
            <div className="container mx-auto p-6">
                <h2 className="text-3xl font-semibold mb-6 text-gray-100">Upload Excel Sheet</h2>

                {message && (
                    <p className={`mb-4 ${message.includes('Failed') ? 'text-red-500' : 'text-green-500'}`}>
                        {message}
                    </p>
                )}

                <div
                    className={`bg-[#2f2f2f] p-6 rounded shadow-md mb-6 border-dashed border-4 ${dragActive ? 'border-blue-600' : 'border-gray-600'}`}
                    onDragEnter={handleDrag}
                    onDragOver={handleDrag}
                    onDragLeave={handleDrag}
                    onDrop={handleDrop}
                >
                    <input
                        type="file"
                        id="file-upload"
                        onChange={handleFileChange}
                        className="hidden"
                    />
                    <label htmlFor="file-upload" className="block text-center mb-4 text-gray-400 cursor-pointer">
                        {file ? `Selected file: ${file.name}` : 'Drag and drop an Excel file or click to select'}
                    </label>
                    <select
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500 mb-4"
                        value={projectId}
                        onChange={handleProjectChange} // Handle both projectCode and projectId
                    >
                        <option value="" disabled>Select Project ID</option>
                        {projects.map((project) => (
                            <option key={project.id} value={project.id}>
                                {project.project_id} - {project.project_name}
                            </option>
                        ))}
                    </select>

                    <button
                        onClick={handleUpload}
                        className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition duration-200 w-full mt-4"
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Upload and Analyze'}
                    </button>
                </div>

                {data.length > 0 && (
                    <div className="bg-[#2f2f2f] p-6 rounded shadow-md">
                        <h4 className="text-2xl font-semibold mb-4 text-gray-100">Review and Edit Data:</h4>
                        <table className="w-full text-left mb-6">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 text-gray-400">Part Number</th>
                                    <th className="py-2 px-4 text-gray-400">Quantity</th>
                                    <th className="py-2 px-4 text-gray-400">Operator ID</th>
                                    <th className="py-2 px-4 text-gray-400">CNC ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {editData.map((item, index) => (
                                    <tr key={index}>
                                        <td className="py-2 px-4">
                                            <input
                                                type="text"
                                                className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                                value={item.partNumber || ''}
                                                onChange={(e) => handleEditChange(index, 'partNumber', e.target.value)}
                                            />
                                        </td>
                                        <td className="py-2 px-4">
                                            <input
                                                type="number"
                                                className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                                value={item.miktar || ''}
                                                onChange={(e) => handleEditChange(index, 'miktar', e.target.value)}
                                            />
                                        </td>
                                        <td className="py-2 px-4">
                                            <select
                                                className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                                value={item.operatorId || ''}
                                                onChange={(e) => handleEditChange(index, 'operatorId', e.target.value)}
                                            >
                                                <option value="" disabled>Select Operator</option>
                                                {operators.map((operator) => (
                                                    <option key={operator.id} value={operator.id}>
                                                        {operator.full_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td className="py-2 px-4">
                                            <select
                                                className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                                value={item.cncId || ''}
                                                onChange={(e) => handleEditChange(index, 'cncId', e.target.value)}
                                            >
                                                <option value="" disabled>Select CNC</option>
                                                {cncCodes.map((cnc) => (
                                                    <option key={cnc.id} value={cnc.id}>
                                                        {cnc.cnc_code}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button
                            onClick={handleConfirm}
                            className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200"
                        >
                            Confirm and Insert
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UploadExcelPage;
