import React from 'react';

const NotFound = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-800 text-white">
            <div className="text-center">
                <h1 className="text-6xl font-bold mb-4">404</h1>
                <p className="text-xl mb-8">Çok gezme buralarda..</p>
                <img 
                    src="https://media1.tenor.com/m/EZtOA2gY9VIAAAAd/find-out-fuck-around.gif" 
                    alt="Not Found" 
                    className="mx-auto mb-8" 
                />
                <a href="/" className="text-blue-500 underline">Ana Sayfaya Dön</a>
            </div>
        </div>
    );
};

export default NotFound;
