import React from 'react';
import unauthorizedImage from '../images/Unauthorized.jpg';

const Unauthorized = () => {
    return (
        <div 
            className="flex items-center justify-center h-screen text-white" 
            style={{ 
                animation: 'flash 1s infinite' 
            }}
        >
            <div className="text-center">
                <h1 className="text-6xl font-bold mb-4">401</h1>
                <p className="text-xl mb-8">Unauthorized: You need to log in to access this page.</p>
                <img src={unauthorizedImage} alt="Unauthorized" className="mx-auto mb-8" />
                <a href="/login" className="text-blue-500 underline">Go to Login</a>
            </div>
        </div>
    );
};

export default Unauthorized;
