import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import AdminDashboard from './components/AdminDashboard';
import AdminPage from './components/AdminPage';
import ManagerDashboard from './components/ManagerDashboard';
import ProgrammerDashboard from './components/ProgrammerDashboard';
import Logout from './components/Logout';
import AddProjectID from './components/AddProjectID';
import ManageCNCMachines from './components/ManageCNCMachines';
import Unauthorized from './components/Unauthorized';
import Forbidden from './components/Forbidden';
import NotFound from './components/NotFound';
import Layout from './components/Layout';
import QRCodeScanner from './components/QRCodeScanner';
import ProgrammerDashboardMain from './components/ProgrammerDashboardMain';
import UploadExcelPage from './components/UploadExcelPage'
import ProductStatusDashboard from './components/ProductStatusDashboard'
import StatusesDashboard from './components/StatusesDashboard'
import SuppliersDashboard from './components/SuppliersDashboard';

// Define routes for centralized management
const ROUTES = {
    LOGIN: '/login',
    ADMIN_DASHBOARD: '/admin-dashboard',
    MANAGER_DASHBOARD: '/manager-dashboard',
    PROGRAMMER_DASHBOARD: '/programmer-dashboard',
    ADD_PROJECT_ID: '/add-project-id',
    MANAGE_CNC_CODES: '/manage-cnc-codes',
    LOGOUT: '/logout',
    UNAUTHORIZED: '/unauthorized',
    FORBIDDEN: '/forbidden',
    MANAGE_USERS: '/admin-dashboard/manage-users',
    QR_SCANNER: '/qr-scanner',
    ADD_PRODUCT: '/programmer-dashboard/add-traceability',
    EXCEL_UPLOAD: '/programmer-dashboard/upload-excel',
    PRODUCT_STATUS: '/PSTAT',
    STATUS_MANAGEMENT: '/status-management',
    SUPPLIERS_MANAGEMENT: '/suppliers-management'
};

function App() {
    return (
        <Router>
            <Routes>
                {/* Redirect root to login */}
                <Route path="/" element={<Navigate to={ROUTES.LOGIN} />} />

                {/* Public Route for Login */}
                <Route path={ROUTES.LOGIN} element={<Login />} />

                {/* Admin Routes */}
                <Route
                    path={ROUTES.ADMIN_DASHBOARD}
                    element={
                        <ProtectedRoute roles={['Admin']}>
                            <Layout>
                                <AdminDashboard />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.MANAGE_USERS}
                    element={
                        <ProtectedRoute roles={['Admin']}>
                            <Layout>
                                <AdminPage />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.STATUS_MANAGEMENT}
                    element={
                        <ProtectedRoute roles={['Admin', 'Manager', 'Programmer']}>
                            <Layout>
                                <StatusesDashboard />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.EXCEL_UPLOAD}
                    element={
                        <ProtectedRoute roles={['Programmer', 'Manager', 'Admin']}>
                            <Layout>
                                <UploadExcelPage />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.PRODUCT_STATUS}
                    element={
                        <ProtectedRoute roles={['Programmer', 'Manager', 'Admin']}>
                            <Layout>
                                <ProductStatusDashboard />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                {/* Manager Routes */}
                <Route
                    path={ROUTES.MANAGER_DASHBOARD}
                    element={
                        <ProtectedRoute roles={['Manager', 'Admin']}>
                            <Layout>
                                <ManagerDashboard />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                {/* Programmer Routes */}
                <Route
                    path={ROUTES.PROGRAMMER_DASHBOARD}
                    element={
                        <ProtectedRoute roles={['Programmer', 'Manager', 'Admin']}>
                            <Layout>
                                <ProgrammerDashboardMain />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.ADD_PRODUCT}
                    element={
                        <ProtectedRoute roles={['Programmer', 'Manager', 'Admin']}>
                            <Layout>
                                <ProgrammerDashboard />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.ADD_PROJECT_ID}
                    element={
                        <ProtectedRoute roles={['Programmer', 'Manager', 'Admin']}>
                            <Layout>
                                <AddProjectID />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.MANAGE_CNC_CODES}
                    element={
                        <ProtectedRoute roles={['Programmer', 'Manager', 'Admin']}>
                            <Layout>
                                <ManageCNCMachines />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                {/* QR Code Scanner Route without Layout */}
                <Route
                    path={ROUTES.QR_SCANNER}
                    element={
                        <ProtectedRoute roles={['Operator', 'Programmer', 'Manager', 'Admin']}>
                            <QRCodeScanner />
                        </ProtectedRoute>
                    }
                />

                {/* Logout Route */}
                <Route path={ROUTES.LOGOUT} element={<Logout />} />

                {/* Error and Unauthorized Pages */}
                <Route path={ROUTES.UNAUTHORIZED} element={<Unauthorized />} />
                <Route path={ROUTES.FORBIDDEN} element={<Forbidden />} />

                {/* 404 Not Found Page */}
                <Route path="*" element={<NotFound />} />
                
                <Route
                    path={ROUTES.SUPPLIERS_MANAGEMENT}
                    element={
                        <ProtectedRoute roles={['Admin', 'Manager', 'Programmer']}>
                            <Layout>
                                <SuppliersDashboard />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
