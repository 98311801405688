import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../output.css';

const AddProjectID = () => {
    const [projectID, setProjectID] = useState('');
    const [projectName, setProjectName] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [projects, setProjects] = useState([]);
    const [editingProject, setEditingProject] = useState(null);
    const [message, setMessage] = useState({ text: '', type: '' });
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    // Fetch user's role from localStorage
    const userRole = localStorage.getItem('role'); // Assuming role is stored in localStorage

    useEffect(() => {
        fetchProjects(page, limit);
    }, [page, limit]);

    const fetchProjects = async (page = 1, limit = 10) => {
        try {
            const response = await axios.get('https://soldrena.com/api/projects/filter', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: {
                    start_date: startDate || undefined,
                    end_date: endDate || undefined,
                    project_id: projectID || undefined,
                    page,
                    limit,
                },
            });
    
            // Ensure response.data is an array
            if (Array.isArray(response.data.projects)) {
                const updatedProjects = response.data.projects.map((project) => ({
                    ...project,
                    not_active: parseInt(project.not_active, 10),
                }));
                setProjects(updatedProjects);
            } else {
                console.error('Unexpected data structure:', response.data);
                setProjects([]); // Or handle it in another way, e.g., showing an error message
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    const handleAddProject = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                'https://soldrena.com/api/projects/add',
                {
                    project_id: projectID,
                    project_name: projectName,
                    description,
                    start_date: startDate || null,
                    end_date: endDate || null,
                    not_active: 0,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );

            setMessage({ text: response.data.message, type: 'success' });
            setProjectID('');
            setProjectName('');
            setDescription('');
            setStartDate('');
            setEndDate('');
            fetchProjects(page, limit);
        } catch (error) {
            console.error('Error adding project:', error);
            setMessage({ text: 'Failed to add project', type: 'error' });
        }
    };

    const handleToggleActive = async (id, notActive) => {
        try {
            await axios.put(
                `https://soldrena.com/api/projects/update-status/${id}`,
                {
                    not_active: notActive ? 0 : 1,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );

            setProjects(
                projects.map((project) =>
                    project.id === id ? { ...project, not_active: notActive ? 0 : 1 } : project
                )
            );

            setMessage({ text: 'Project status updated successfully', type: 'success' });
        } catch (error) {
            console.error('Error updating project status:', error.response?.data || error.message);
            setMessage({ text: 'Failed to update project status', type: 'error' });
        }
    };

    const handleEditProject = (project) => {
        setEditingProject(project);
    };

    const handleUpdateProject = async () => {
        if (!editingProject) return;
        try {
            await axios.put(
                `https://soldrena.com/api/projects/update/${editingProject.id}`,
                editingProject,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            setMessage({ text: 'Project updated successfully!', type: 'success' });
            setEditingProject(null);
            fetchProjects(page, limit);
        } catch (error) {
            console.error('Error updating project:', error);
            setMessage({ text: 'Failed to update project', type: 'error' });
        }
    };

    const handleDeleteProject = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this project?');
        if (confirmed) {
            try {
                await axios.delete(`https://soldrena.com/api/projects/delete/${id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setMessage({ text: 'Project deleted successfully', type: 'success' });
                fetchProjects(page, limit);
            } catch (error) {
                console.error('Error deleting project:', error);
                setMessage({ text: 'Failed to delete project', type: 'error' });
            }
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200">
            <div className="container mx-auto p-4">
                <h2 className="text-3xl font-semibold mb-6 text-gray-100">Yeni Proje Ekle</h2>
                {message.text && (
                    <p className={`mb-4 ${message.type === 'error' ? 'text-red-500' : 'text-green-500'}`}>
                        {message.text}
                    </p>
                )}
                <form onSubmit={handleAddProject} className="bg-[#212121] p-6 rounded shadow-md">
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2">Proje Kodu</label>
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="text"
                            value={projectID}
                            onChange={(e) => setProjectID(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2">Proje Adı</label>
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="text"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2">Açıklama</label>
                        <textarea
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2">Başlangıç Tarihi</label>
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2">Bitiş Tarihi</label>
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200">
                        Add Project
                    </button>
                </form>

                <h2 className="text-3xl font-semibold mb-6 mt-8 text-gray-100">Projeler</h2>
                <table className="min-w-full bg-[#2f2f2f] shadow-md rounded overflow-hidden">
                    <thead className="bg-[#2f2f2f]">
                        <tr>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Proje Kodu</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Proje Adı</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Açıklama</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Başlangıç Tarihi</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Bitiş Tarihi</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-center text-gray-400 font-semibold">Aktif</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-center text-gray-400 font-semibold">Aksiyonlar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projects.map((project) => (
                            <tr
                                key={project.id}
                                className={`${
                                    editingProject?.id === project.id ? 'bg-gray-900' : 'hover:bg-gray-900'
                                } text-gray-200 transition duration-200`}
                            >
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingProject?.id === project.id ? (
                                        <input
                                            type="text"
                                            className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            value={editingProject.project_id}
                                            onChange={(e) =>
                                                setEditingProject({ ...editingProject, project_id: e.target.value })
                                            }
                                        />
                                    ) : (
                                        <span className="text-gray-200">{project.project_id}</span>
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingProject?.id === project.id ? (
                                        <input
                                            type="text"
                                            className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            value={editingProject.project_name}
                                            onChange={(e) =>
                                                setEditingProject({ ...editingProject, project_name: e.target.value })
                                            }
                                        />
                                    ) : (
                                        <span className="text-gray-200">{project.project_name}</span>
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingProject?.id === project.id ? (
                                        <textarea
                                            className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            value={editingProject.description}
                                            onChange={(e) =>
                                                setEditingProject({ ...editingProject, description: e.target.value })
                                            }
                                        />
                                    ) : (
                                        <span className="text-gray-200">{project.description}</span>
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingProject?.id === project.id ? (
                                        <input
                                            type="date"
                                            className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            value={editingProject.start_date}
                                            onChange={(e) =>
                                                setEditingProject({ ...editingProject, start_date: e.target.value })
                                            }
                                        />
                                    ) : (
                                        <span className="text-gray-200">{project.start_date}</span>
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingProject?.id === project.id ? (
                                        <input
                                            type="date"
                                            className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            value={editingProject.end_date}
                                            onChange={(e) =>
                                                setEditingProject({ ...editingProject, end_date: e.target.value })}
                                        />
                                    ) : (
                                        <span className="text-gray-200">{project.end_date}</span>
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600 text-center">
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${project.id}`}
                                        className="hidden"
                                        checked={!project.not_active}
                                        onChange={() => handleToggleActive(project.id, project.not_active)}
                                    />
                                    <label
                                        htmlFor={`checkbox-${project.id}`}
                                        className={`flex items-center justify-center h-8 w-8 border border-gray-400 rounded-md cursor-pointer ${
                                            !project.not_active ? 'bg-black' : 'bg-transparent'
                                        }`}
                                    >
                                        <svg
                                            className={`w-6 h-6 ${!project.not_active ? 'text-white' : 'text-[#2f2f2f]'}`}
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M16.707 5.293a1 1 0 00-1.414 0L7 13.586 4.707 11.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l9-9a1 1 0 000-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </label>
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600 text-center">
                                    {editingProject?.id === project.id ? (
                                        <>
                                            <button
                                                className="bg-green-600 hover:bg-green-700 text-white p-2 rounded mr-2 transition duration-200"
                                                onClick={handleUpdateProject}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="bg-gray-600 hover:bg-gray-900 text-white p-2 rounded transition duration-200"
                                                onClick={() => setEditingProject(null)}
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                className="bg-yellow-600 hover:bg-yellow-700 text-white p-2 rounded mr-2 transition duration-200"
                                                onClick={() => handleEditProject(project)}
                                            >
                                                Edit
                                            </button>
                                            {/* Only show Delete button if the user is an Admin */}
                                            {userRole === 'Admin' && (
                                                <button
                                                    className="bg-red-600 hover:bg-red-700 text-white p-2 rounded transition duration-200"
                                                    onClick={() => handleDeleteProject(project.id)}
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination mt-4 flex justify-center items-center">
                    <button
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 1}
                        className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-l"
                    >
                        Previous
                    </button>
                    <span className="mx-4 text-gray-400">Page {page}</span>
                    <button
                        onClick={() => handlePageChange(page + 1)}
                        disabled={projects.length < limit}
                        className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-r"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddProjectID;
