import React, { useState } from 'react';
import Menu from './Menu'; // Ensure correct path to the Menu component
import '../output.css'; // Global CSS for custom styles

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex min-h-screen bg-[#1a1a1a] text-white">
      {/* Mobile Menu Toggle */}
      <button
        onClick={() => setMenuOpen(!menuOpen)}
        className="fixed top-4 left-4 z-50 p-3 text-white bg-gray-800 rounded-md md:hidden"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'} />
        </svg>
      </button>

      {/* Fixed Menu */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gray-900 text-white shadow-lg transform ${
          menuOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out md:translate-x-0 md:fixed md:h-full md:w-64`}
      >
        <Menu closeMenu={() => setMenuOpen(false)} />
      </div>

      {/* Main Content */}
      <div className="flex-1 p-8 overflow-y-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-700 scrollbar-track-gray-900 md:ml-64">
        {children}
      </div>
    </div>
  );
};

export default Layout;
