import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash, FaSave, FaTimes, FaFileExcel, FaArrowLeft, FaArrowRight, FaPlus, FaSearch, FaSort } from 'react-icons/fa';
import { debounce } from 'lodash';

const ProductStatusDashboard = () => {
    const [trackingData, setTrackingData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [users, setUsers] = useState([]);
    const [filters, setFilters] = useState({
        project: '',
        status: '',
        supplier: '',
        startTime: new Date(new Date().setDate(1)).toISOString().split('T')[0], // First day of the month
        endTime: new Date(new Date().setHours(23, 59, 59, 999)).toISOString().split('T')[0], // Last moment of the current day
    });
    const [pagination, setPagination] = useState({ limit: 10, offset: 0, page: 1, totalPages: 1 });
    const [message, setMessage] = useState({ text: '', type: '' });
    const [editingRowId, setEditingRowId] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [showAddForm, setShowAddForm] = useState(false);
    const [newData, setNewData] = useState({
        project_id: '',
        cam_code: '',
        status_id: '',
        supplier_id: '',
        notes: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        fetchDropdownData();
        fetchTrackingData();
    }, [filters, pagination.offset, pageSize]);

    const fetchDropdownData = async () => {
        try {
            const [projectsResponse, statusesResponse, suppliersResponse, usersResponse] = await Promise.all([
                axios.get('https://soldrena.com/api/product-status-tracking/projects', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }),
                axios.get('https://soldrena.com/api/product-status-tracking/statuses', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }),
                axios.get('https://soldrena.com/api/product-status-tracking/suppliers', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }),
                axios.get('https://soldrena.com/api/product-status-tracking/users', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            ]);
            setProjects(projectsResponse.data);
            setStatuses(statusesResponse.data);
            setSuppliers(suppliersResponse.data);
            setUsers(usersResponse.data.map(u => ({ id: u.id, name: u.username })));
        } catch (error) {
            console.error('Açılır veriler alınırken hata oluştu:', error);
        }
    };

    const fetchTrackingData = async () => {
        try {
            const response = await axios.get('https://soldrena.com/api/product-status-tracking', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: {
                    ...filters,
                    startTime: filters.startTime,
                    endTime: new Date(new Date(filters.endTime).setHours(23, 59, 59, 999)).toISOString(),
                    search: searchTerm,
                    limit: pageSize,
                    offset: (pagination.page - 1) * pageSize,
                },
            });
            setTrackingData(response.data.rows);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalPages: Math.ceil(response.data.total / pageSize)
            }));
        } catch (error) {
            console.error('Ürün durumu verileri alınırken hata oluştu:', error);
            setMessage({ text: 'Veriler alınamadı', type: 'error' });
        }
    };

    const handleUpdate = async (id, updatedData) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            await axios.put(`https://soldrena.com/api/product-status-tracking/update/${id}`, 
                updatedData,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setMessage({ text: 'Veri başarıyla güncellendi', type: 'success' });
            setEditingRowId(null);
            fetchTrackingData();
        } catch (error) {
            setMessage({ text: 'Veri güncellenemedi: ' + (error.response?.data?.error || error.message), type: 'error' });
            console.error('Veri güncellenirken hata oluştu:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://soldrena.com/api/product-status-tracking/delete/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessage({ text: 'Veri başarıyla silindi', type: 'success' });
            setDeleteConfirm(null);
            fetchTrackingData();
        } catch (error) {
            setMessage({ text: 'Veri silinemedi', type: 'error' });
            console.error('Veri silinirken hata oluştu:', error);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleExportToExcel = async () => {
        try {
            const response = await axios.get('https://soldrena.com/api/product-status-tracking/export', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: filters,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'urun_durum_izleme.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Veri dışa aktarılırken hata oluştu:', error);
        }
    };

    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
        setPagination((prev) => ({ ...prev, limit: Number(e.target.value), page: 1 }));
    };

    const handlePageInputChange = (e) => {
        const page = Number(e.target.value);
        if (page > 0 && page <= pagination.totalPages) {
            setPagination((prev) => ({
                ...prev,
                page,
                offset: (page - 1) * prev.limit,
            }));
        }
    };

    const startEditing = (id) => {
        setEditingRowId(id);
    };

    const cancelEditing = () => {
        setEditingRowId(null);
    };

    const saveEditing = (id) => {
        const updatedRow = trackingData.find((item) => item.id === id);
        if (!updatedRow) {
            setMessage({ text: 'Güncellenecek veri bulunamadı.', type: 'error' });
            return;
        }

        const project = projects.find((p) => p.project_id === updatedRow.project_code);
        const status = statuses.find((s) => s.status_name === updatedRow.status_name);
        const supplier = suppliers.find((s) => s.supplier_name === updatedRow.supplier_name);

        const updatedData = {
            project_id: project ? project.id : null,
            cam_code: updatedRow.cam_code,
            status_id: status ? status.id : null,
            supplier_id: supplier ? supplier.id : null,
            notes: updatedRow.notes || '',
        };

        // Validation
        if (!updatedData.project_id) {
            setMessage({ text: 'Geçerli bir proje seçin.', type: 'error' });
            return;
        }
        if (!updatedData.status_id) {
            setMessage({ text: 'Geçerli bir durum seçin.', type: 'error' });
            return;
        }
        if (!updatedData.supplier_id) {
            setMessage({ text: 'Geçerli bir tedarikçi seçin.', type: 'error' });
            return;
        }

        // Proceed with the update
        handleUpdate(id, updatedData);
    };

    const handleInputChange = (id, field, value) => {
        setTrackingData((prevData) =>
            prevData.map((item) =>
                item.id === id ? { ...item, [field]: value || '' } : item
            )
        );
    };

    const handleAddData = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://soldrena.com/api/qr-codes/scan', {
                productId: newData.cam_code,
                supplierId: newData.supplier_id,
                statusId: newData.status_id,
                projectId: newData.project_id,
                notes: newData.notes
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessage({ text: response.data.message, type: 'success' });
            setShowAddForm(false);
            setNewData({
                project_id: '',
                cam_code: '',
                status_id: '',
                supplier_id: '',
                notes: ''
            });
            fetchTrackingData();
        } catch (error) {
            setMessage({ text: 'Veri eklenemedi: ' + (error.response?.data?.error || error.message), type: 'error' });
        }
    };

    const fetchProductMatches = async (value) => {
        if (value.length >= 2 && newData.project_id) {
            const token = localStorage.getItem('token');
            if (!token) {
                setMessage({ text: 'Token bulunamadı, lütfen tekrar giriş yapın.', type: 'error' });
                return;
            }

            try {
                const response = await axios.get(`https://soldrena.com/api/traceability/search?query=${value}&project_id=${newData.project_id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setSuggestions(response.data);
                setShowSuggestions(true);
            } catch (error) {
                console.error('Ürün eşleşmeleri alınırken hata oluştu:', error);
                setSuggestions([]);
                setShowSuggestions(false);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const debouncedFetchProductMatches = debounce(fetchProductMatches, 300);

    const handleNewDataChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => ({ ...prev, [name]: value }));
        if (name === 'cam_code') {
            debouncedFetchProductMatches(value);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setNewData(prev => ({ ...prev, cam_code: suggestion.cam_code }));
        setShowSuggestions(false);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        fetchTrackingData();
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        let sortableItems = [...trackingData];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [trackingData, sortConfig]);

    const renderMobileCard = (data) => (
        <div key={data.id} className="bg-[#2f2f2f] p-4 mb-4 rounded-lg">
            <p><strong>CAM Kodu:</strong> {data.cam_code}</p>
            <p><strong>Proje Kodu:</strong> {data.project_code}</p>
            <p><strong>Durum:</strong> {data.status_name}</p>
            <p><strong>Tedarikçi:</strong> {data.supplier_name}</p>
            <p><strong>Ekleyen:</strong> {data.updated_by_user}</p>
            <p><strong>Son Güncelleme:</strong> {data.updated_at}</p>
            <p><strong>Notlar:</strong> {data.notes}</p>
            <div className="mt-2">
                <button
                    onClick={() => startEditing(data.id)}
                    className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 w-full mb-2"
                >
                    <FaEdit className="mr-1 inline" /> Düzenle
                </button>
                {localStorage.getItem('role') === 'Admin' && (
                    <button
                        onClick={() => setDeleteConfirm(data.id)}
                        className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 w-full"
                    >
                        <FaTrash className="mr-1 inline" /> Sil
                    </button>
                )}
            </div>
        </div>
    );

    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200">
            <div className="container mx-auto p-4">
                <h2 className="text-3xl font-semibold mb-6 text-gray-100">Ürün Durum İzleme Paneli</h2>

                {message.text && (
                    <p className={`mb-4 ${message.type === 'error' ? 'text-red-500' : 'text-green-500'}`}>
                        {message.text}
                    </p>
                )}

                {/* Add new data button */}
                <button
                    onClick={() => setShowAddForm(!showAddForm)}
                    className="mb-4 bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 flex items-center"
                >
                    <FaPlus className="mr-2" /> Yeni Veri Ekle
                </button>

                {/* Add new data form */}
                {showAddForm && (
                    <form onSubmit={handleAddData} className="mb-6 bg-[#2f2f2f] p-4 rounded shadow-md">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-gray-400 text-sm font-semibold mb-2">Proje</label>
                                <select
                                    name="project_id"
                                    value={newData.project_id}
                                    onChange={handleNewDataChange}
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                                    required
                                >
                                    <option value="">Proje Seçin</option>
                                    {projects.map(project => (
                                        <option key={project.id} value={project.id}>
                                            {project.project_id} - {project.project_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="relative">
                                <label className="block text-gray-400 text-sm font-semibold mb-2">CAM Kodu</label>
                                <input
                                    type="text"
                                    name="cam_code"
                                    value={newData.cam_code}
                                    onChange={handleNewDataChange}
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                                    required
                                />
                                {showSuggestions && suggestions.length > 0 && (
                                    <ul className="absolute z-10 w-full bg-gray-800 border border-gray-600 rounded-md mt-1 max-h-40 overflow-y-auto">
                                        {suggestions.map((suggestion) => (
                                            <li
                                                key={suggestion.cam_code}
                                                onClick={() => handleSuggestionClick(suggestion)}
                                                className="px-3 py-2 hover:bg-gray-700 cursor-pointer"
                                            >
                                                {suggestion.cam_code}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div>
                                <label className="block text-gray-400 text-sm font-semibold mb-2">Durum</label>
                                <select
                                    name="status_id"
                                    value={newData.status_id}
                                    onChange={handleNewDataChange}
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                                    required
                                >
                                    <option value="">Durum Seçin</option>
                                    {statuses.map(status => (
                                        <option key={status.id} value={status.id}>
                                            {status.status_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="block text-gray-400 text-sm font-semibold mb-2">Tedarikçi</label>
                                <select
                                    name="supplier_id"
                                    value={newData.supplier_id}
                                    onChange={handleNewDataChange}
                                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                                    required
                                >
                                    <option value="">Tedarikçi Seçin</option>
                                    {suppliers.map(supplier => (
                                        <option key={supplier.id} value={supplier.id}>
                                            {supplier.supplier_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mt-4">
                            <label className="block text-gray-400 text-sm font-semibold mb-2">Notlar</label>
                            <textarea
                                name="notes"
                                value={newData.notes}
                                onChange={handleNewDataChange}
                                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                                rows="3"
                            ></textarea>
                        </div>
                        <div className="mt-4 flex justify-end">
                            <button
                                type="submit"
                                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200"
                            >
                                Ekle
                            </button>
                        </div>
                    </form>
                )}

                {/* Search form */}
                <form onSubmit={handleSearch} className="mb-4 flex space-x-2">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="CAM Kodu veya Proje Kodu ile ara"
                        className="flex-grow border border-gray-600 rounded py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                    />
                    <button
                        type="submit"
                        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200"
                    >
                        Ara
                    </button>
                </form>

                {isMobile ? (
                    <button
                        onClick={() => setShowFilters(!showFilters)}
                        className="w-full mb-4 bg-blue-600 text-white py-2 px-4 rounded"
                    >
                        {showFilters ? 'Filtreleri Gizle' : 'Filtreleri Göster'}
                    </button>
                ) : null}

                {(isMobile && showFilters) || !isMobile ? (
                    <div className={`mb-4 ${isMobile ? 'flex flex-col space-y-2' : 'flex space-x-2'}`}>
                        <select
                            name="project"
                            value={filters.project}
                            onChange={handleFilterChange}
                            className="border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                        >
                            <option value="">Tüm Projeler</option>
                            {projects.map((project) => (
                                <option key={project.id} value={project.project_id}>
                                    {project.project_id} - {project.project_name}
                                </option>
                            ))}
                        </select>
                        <select
                            name="status"
                            value={filters.status}
                            onChange={handleFilterChange}
                            className="border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                        >
                            <option value="">Tüm Durumlar</option>
                            {statuses.map((status) => (
                                <option key={status.id} value={status.status_name}>
                                    {status.status_name}
                                </option>
                            ))}
                        </select>
                        <select
                            name="supplier"
                            value={filters.supplier}
                            onChange={handleFilterChange}
                            className="border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                        >
                            <option value="">Tüm Tedarikçiler</option>
                            {suppliers.map((supplier) => (
                                <option key={supplier.id} value={supplier.supplier_name}>
                                    {supplier.supplier_name}
                                </option>
                            ))}
                        </select>
                        <input
                            type="date"
                            name="startTime"
                            value={filters.startTime}
                            onChange={handleFilterChange}
                            className="border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                        />
                        <input
                            type="date"
                            name="endTime"
                            value={filters.endTime}
                            onChange={handleFilterChange}
                            className="border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                        />
                        <button
                            onClick={handleExportToExcel}
                            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 flex items-center"
                        >
                            <FaFileExcel className="mr-2" /> Excel'e Aktar
                        </button>
                    </div>
                ) : null}

                {isMobile ? (
                    sortedData.map(renderMobileCard)
                ) : (
                    <table className="min-w-full bg-[#2f2f2f] shadow-md rounded overflow-hidden">
                        <thead className="bg-[#2f2f2f]">
                            <tr>
                                {['cam_code', 'project_code', 'status_name', 'supplier_name', 'updated_by_user', 'updated_at'].map((key) => (
                                    <th 
                                        key={key}
                                        className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold cursor-pointer"
                                        onClick={() => handleSort(key)}
                                    >
                                        {key === 'cam_code' ? 'CAM Kodu' :
                                         key === 'project_code' ? 'Proje Kodu' :
                                         key === 'status_name' ? 'Durum' :
                                         key === 'supplier_name' ? 'Tedarikçi' :
                                         key === 'updated_by_user' ? 'Ekleyen' :
                                         key === 'updated_at' ? 'Son Güncelleme' : key}
                                        <FaSort className="inline ml-1" />
                                    </th>
                                ))}
                                <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Notlar</th>
                                <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold w-40">İşlemler</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((data) => (
                                <tr key={data.id} className="hover:bg-gray-900 text-gray-200 transition duration-200">
                                    {editingRowId === data.id ? (
                                        <>
                                            <td className="py-3 px-4 border-b border-gray-600">{data.cam_code}</td>
                                            <td className="py-3 px-4 border-b border-gray-600">
                                                <select
                                                    value={data.project_code || ''}
                                                    onChange={(e) => handleInputChange(data.id, 'project_code', e.target.value)}
                                                    className="bg-gray-700 text-white py-1 px-2 rounded"
                                                >
                                                    {projects.map((project) => (
                                                        <option key={project.id} value={project.project_id}>
                                                            {project.project_id}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td className="py-3 px-4 border-b border-gray-600">
                                                <select
                                                    value={data.status_name || ''}
                                                    onChange={(e) => handleInputChange(data.id, 'status_name', e.target.value)}
                                                    className="bg-gray-700 text-white py-1 px-2 rounded"
                                                >
                                                    {statuses.map((status) => (
                                                        <option key={status.id} value={status.status_name}>
                                                            {status.status_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td className="py-3 px-4 border-b border-gray-600">
                                                <select
                                                    value={data.supplier_name || ''}
                                                    onChange={(e) => handleInputChange(data.id, 'supplier_name', e.target.value)}
                                                    className="bg-gray-700 text-white py-1 px-2 rounded"
                                                >
                                                    {suppliers.map((supplier) => (
                                                        <option key={supplier.id} value={supplier.supplier_name}>
                                                            {supplier.supplier_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td className="py-3 px-4 border-b border-gray-600">{data.updated_by_user}</td>
                                            <td className="py-3 px-4 border-b border-gray-600">{data.updated_at}</td>
                                            <td className="py-3 px-4 border-b border-gray-600">
                                                <input
                                                    type="text"
                                                    value={data.notes || ''}
                                                    onChange={(e) => handleInputChange(data.id, 'notes', e.target.value)}
                                                    className="bg-gray-700 text-white py-1 px-2 rounded w-full"
                                                />
                                            </td>
                                            <td className="py-3 px-4 border-b border-gray-600 w-40">
                                                <div className="flex space-x-2">
                                                    <button
                                                        onClick={() => saveEditing(data.id)}
                                                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200 flex items-center"
                                                    >
                                                        <FaSave className="mr-1" /> Kaydet
                                                    </button>
                                                    <button
                                                        onClick={cancelEditing}
                                                        className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200 flex items-center"
                                                    >
                                                        <FaTimes className="mr-1" /> İptal
                                                    </button>
                                                </div>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td className="py-3 px-4 border-b border-gray-600">{data.cam_code}</td>
                                            <td className="py-3 px-4 border-b border-gray-600">{data.project_code}</td>
                                            <td className="py-3 px-4 border-b border-gray-600">{data.status_name}</td>
                                            <td className="py-3 px-4 border-b border-gray-600">{data.supplier_name}</td>
                                            <td className="py-3 px-4 border-b border-gray-600">{data.updated_by_user}</td>
                                            <td className="py-3 px-4 border-b border-gray-600">{data.updated_at}</td>
                                            <td className="py-3 px-4 border-b border-gray-600">{data.notes}</td>
                                            <td className="py-3 px-4 border-b border-gray-600 w-40">
                                                <div className="flex space-x-2">
                                                    <button
                                                        onClick={() => startEditing(data.id)}
                                                        className="bg-green-500 hover:bg-green-600 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200 flex items-center"
                                                    >
                                                        <FaEdit className="mr-1" /> Düzenle
                                                    </button>
                                                    {localStorage.getItem('role') === 'Admin' && (
                                                        <button
                                                            onClick={() => setDeleteConfirm(data.id)}
                                                            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200 flex items-center"
                                                        >
                                                            <FaTrash className="mr-1" /> Sil
                                                        </button>
                                                    )}
                                                </div>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                <div className="flex justify-between mt-4">
                    <select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        className="bg-gray-700 text-white py-2 px-4 rounded shadow-md"
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                    <div className="flex items-center space-x-2">
                        <input
                            type="number"
                            value={pagination.page}
                            onChange={handlePageInputChange}
                            className="bg-gray-700 text-white py-2 px-4 rounded shadow-md w-16"
                        />
                        <span className="text-gray-400">/ {pagination.totalPages}</span>
                        <button
                            onClick={() => handlePageInputChange({ target: { value: pagination.page - 1 } })}
                            disabled={pagination.page === 1}
                            className="bg-gray-700 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 flex items-center"
                        >
                            <FaArrowLeft />
                        </button>
                        <button
                            onClick={() => handlePageInputChange({ target: { value: pagination.page + 1 } })}
                            disabled={pagination.page === pagination.totalPages}
                            className="bg-gray-700 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 flex items-center"
                        >
                            <FaArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductStatusDashboard;
                               