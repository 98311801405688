import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SuppliersDashboard = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [newSupplier, setNewSupplier] = useState({ name: '', contactInfo: '' });
    const [editingId, setEditingId] = useState(null);
    const [editingSupplier, setEditingSupplier] = useState({ name: '', contactInfo: '' });
    const [message, setMessage] = useState({ text: '', type: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchSuppliers();
    }, []);

    const fetchSuppliers = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://soldrena.com/api/suppliers', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setSuppliers(response.data);
        } catch (error) {
            console.error('Error fetching suppliers:', error);
            setMessage({ text: 'Failed to fetch suppliers', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newSupplier.name.trim()) {
            setMessage({ text: 'Supplier name cannot be empty', type: 'error' });
            return;
        }
        setLoading(true);
        const supplierData = {
            supplier_name: newSupplier.name,
            contact_info: newSupplier.contactInfo
        };
        console.log('Sending supplier data:', supplierData);
        try {
            await axios.post('https://soldrena.com/api/suppliers', supplierData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessage({ text: 'Supplier added successfully', type: 'success' });
            setNewSupplier({ name: '', contactInfo: '' });
            fetchSuppliers();
        } catch (error) {
            console.error('Error adding supplier:', error.response?.data || error.message);
            setMessage({ text: 'Failed to add supplier', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (id, supplier) => {
        setEditingId(id);
        setEditingSupplier({ name: supplier.supplier_name, contactInfo: supplier.contact_info });
    };

    const handleUpdate = async (id) => {
        if (!editingSupplier.name.trim()) {
            setMessage({ text: 'Supplier name cannot be empty', type: 'error' });
            return;
        }
        setLoading(true);
        try {
            await axios.put(`https://soldrena.com/api/suppliers/${id}`, {
                supplier_name: editingSupplier.name,
                contact_info: editingSupplier.contactInfo
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessage({ text: 'Supplier updated successfully', type: 'success' });
            setEditingId(null);
            fetchSuppliers();
        } catch (error) {
            console.error('Error updating supplier:', error);
            setMessage({ text: 'Failed to update supplier', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this supplier?')) {
            setLoading(true);
            try {
                await axios.delete(`https://soldrena.com/api/suppliers/${id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setMessage({ text: 'Supplier deleted successfully', type: 'success' });
                fetchSuppliers();
            } catch (error) {
                console.error('Error deleting supplier:', error);
                setMessage({ text: 'Failed to delete supplier', type: 'error' });
            } finally {
                setLoading(false);
            }
        }
    };

    const formatDate = (date) => {
        const d = new Date(date);
        d.setHours(23, 59, 59, 999);
        return d.toLocaleString();
    };

    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200">
            <div className="container mx-auto p-4">
                <h2 className="text-3xl font-semibold mb-6 text-gray-100">Suppliers Dashboard</h2>
                {message.text && (
                    <p className={`mb-4 ${message.type === 'error' ? 'text-red-500' : 'text-green-500'}`}>
                        {message.text}
                    </p>
                )}
                <form onSubmit={handleSubmit} className="mb-8">
                    <div className="flex items-center space-x-2">
                        <input
                            type="text"
                            value={newSupplier.name}
                            onChange={(e) => setNewSupplier({ ...newSupplier, name: e.target.value })}
                            placeholder="Enter supplier name"
                            className="flex-grow p-2 bg-[#2f2f2f] border border-gray-600 rounded"
                        />
                        <input
                            type="text"
                            value={newSupplier.contactInfo}
                            onChange={(e) => setNewSupplier({ ...newSupplier, contactInfo: e.target.value })}
                            placeholder="Enter contact info"
                            className="flex-grow p-2 bg-[#2f2f2f] border border-gray-600 rounded"
                        />
                        <button
                            type="submit"
                            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            disabled={loading}
                        >
                            Add Supplier
                        </button>
                    </div>
                </form>

                <table className="min-w-full bg-[#2f2f2f] shadow-md rounded overflow-hidden">
                    <thead className="bg-[#2f2f2f]">
                        <tr>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">ID</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Supplier Name</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Contact Info</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Created At</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-right text-gray-400 font-semibold">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {suppliers.map((supplier) => (
                            <tr key={supplier.id} className="hover:bg-gray-900 transition duration-200">
                                <td className="py-3 px-4 border-b border-gray-600">{supplier.id}</td>
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingId === supplier.id ? (
                                        <input
                                            type="text"
                                            value={editingSupplier.name}
                                            onChange={(e) => setEditingSupplier({ ...editingSupplier, name: e.target.value })}
                                            className="w-full p-1 bg-[#2f2f2f] border border-gray-600 rounded"
                                        />
                                    ) : (
                                        supplier.supplier_name
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingId === supplier.id ? (
                                        <input
                                            type="text"
                                            value={editingSupplier.contactInfo}
                                            onChange={(e) => setEditingSupplier({ ...editingSupplier, contactInfo: e.target.value })}
                                            className="w-full p-1 bg-[#2f2f2f] border border-gray-600 rounded"
                                        />
                                    ) : (
                                        supplier.contact_info
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600">{formatDate(supplier.created_at)}</td>
                                <td className="py-3 px-4 border-b border-gray-600 text-right">
                                    {editingId === supplier.id ? (
                                        <>
                                            <button
                                                onClick={() => handleUpdate(supplier.id)}
                                                className="bg-green-600 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
                                                disabled={loading}
                                            >
                                                Save
                                            </button>
                                            <button
                                                onClick={() => setEditingId(null)}
                                                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded"
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={() => handleEdit(supplier.id, supplier)}
                                                className="bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => handleDelete(supplier.id)}
                                                className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                            >
                                                Delete
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SuppliersDashboard;