import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../output.css';

const ManageCNCMachines = () => {
    const [cncCode, setCncCode] = useState('');
    const [cncName, setCncName] = useState('');
    const [location, setLocation] = useState('');
    const [cncMachines, setCncMachines] = useState([]);
    const [editingMachine, setEditingMachine] = useState(null);
    const [message, setMessage] = useState({ text: '', type: '' });
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalMachines, setTotalMachines] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false); // Yeni: admin kontrolü için state

    useEffect(() => {
        fetchCncMachines(page, limit);
        checkUserRole(); // Kullanıcı rolünü kontrol et
    }, [page, limit]);

    const fetchCncMachines = async (page = 1, limit = 10) => {
        try {
            const response = await axios.get('https://soldrena.com/api/cnc-machines/filter', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: {
                    cnc_code: cncCode || undefined,
                    page,
                    limit,
                },
            });

            setCncMachines(response.data.machines);
            setTotalMachines(response.data.total);
        } catch (error) {
            console.error('CNC makineleri alınırken hata oluştu:', error);
        }
    };

    const checkUserRole = async () => {
        try {
            // Bu kısımda, kullanıcının rolünü kontrol eden bir API çağrısı yapmanız gerekecek
            const response = await axios.get('https://soldrena.com/api/user/role', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setIsAdmin(response.data.role === 'admin');
        } catch (error) {
            console.error('Kullanıcı rolü kontrol edilirken hata oluştu:', error);
            setIsAdmin(false); // Hata durumunda, güvenli tarafta kalmak için admin olmadığını varsayalım
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleAddMachine = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                'https://soldrena.com/api/cnc-machines/add',
                { cnc_code: cncCode, cnc_name: cncName, location },
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );

            setMessage({ text: 'CNC makinesi başarıyla eklendi.', type: 'success' });
            setCncCode('');
            setCncName('');
            setLocation('');
            fetchCncMachines(1, limit);
            setPage(1);
        } catch (error) {
            console.error('CNC makinesi eklenirken hata oluştu:', error);
            setMessage({ text: 'CNC makinesi eklenemedi.', type: 'error' });
        }
    };

    const handleEditMachine = (machine) => {
        setEditingMachine(machine);
    };

    const handleUpdateMachine = async () => {
        if (!editingMachine) return;
        try {
            await axios.put(
                `https://soldrena.com/api/cnc-machines/update/${editingMachine.id}`,
                editingMachine,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            setMessage({ text: 'CNC makinesi başarıyla güncellendi!', type: 'success' });
            setEditingMachine(null);
            fetchCncMachines(page, limit);
        } catch (error) {
            console.error('CNC makinesi güncellenirken hata oluştu:', error);
            setMessage({ text: 'CNC makinesi güncellenemedi', type: 'error' });
        }
    };

    const handleDeleteMachine = async (id) => {
        const confirmed = window.confirm('Bu CNC makinesini silmek istediğinizden emin misiniz?');
        if (confirmed) {
            try {
                await axios.delete(`https://soldrena.com/api/cnc-machines/delete/${id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setMessage({ text: 'CNC makinesi başarıyla silindi!', type: 'success' });
                fetchCncMachines(page, limit);
            } catch (error) {
                console.error('CNC makinesi silinirken hata oluştu:', error);
                setMessage({ text: 'CNC makinesi silinemedi', type: 'error' });
            }
        }
    };

    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200">
            <div className="container mx-auto p-4">
                <h2 className="text-3xl font-semibold mb-6 text-gray-100">CNC Makinelerini Yönet</h2>
                {message.text && (
                    <p className={`mb-4 ${message.type === 'error' ? 'text-red-500' : 'text-green-500'}`}>
                        {message.text}
                    </p>
                )}
                <form onSubmit={handleAddMachine} className="bg-[#212121] p-6 rounded shadow-md">
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2">CNC Kodu</label>
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="text"
                            value={cncCode}
                            onChange={(e) => setCncCode(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2">CNC Adı</label>
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="text"
                            value={cncName}
                            onChange={(e) => setCncName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 text-sm font-semibold mb-2">Konum</label>
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="text"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </div>
                    <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200">
                        CNC Makinesi Ekle
                    </button>
                </form>

                <h2 className="text-3xl font-semibold mb-6 mt-8 text-gray-100">Mevcut CNC Makineleri</h2>
                <table className="min-w-full bg-[#2f2f2f] shadow-md rounded overflow-hidden">
                    <thead className="bg-[#2f2f2f]">
                        <tr>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">CNC Kodu</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">CNC Adı</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Konum</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-center text-gray-400 font-semibold">İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cncMachines.map((machine) => (
                            <tr
                                key={machine.id}
                                className={`${
                                    editingMachine?.id === machine.id ? 'bg-gray-900' : 'hover:bg-gray-900'
                                } text-gray-200 transition duration-200`}
                            >
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingMachine?.id === machine.id ? (
                                        <input
                                            type="text"
                                            className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            value={editingMachine.cnc_code}
                                            onChange={(e) =>
                                                setEditingMachine({ ...editingMachine, cnc_code: e.target.value })
                                            }
                                        />
                                    ) : (
                                        <span className="text-gray-200">{machine.cnc_code}</span>
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingMachine?.id === machine.id ? (
                                        <input
                                            type="text"
                                            className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            value={editingMachine.cnc_name}
                                            onChange={(e) =>
                                                setEditingMachine({ ...editingMachine, cnc_name: e.target.value })
                                            }
                                        />
                                    ) : (
                                        <span className="text-gray-200">{machine.cnc_name}</span>
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingMachine?.id === machine.id ? (
                                        <input
                                            type="text"
                                            className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                            value={editingMachine.location}
                                            onChange={(e) =>
                                                setEditingMachine({ ...editingMachine, location: e.target.value })
                                            }
                                        />
                                    ) : (
                                        <span className="text-gray-200">{machine.location}</span>
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600 text-center">
                                    {editingMachine?.id === machine.id ? (
                                        <>
                                            <button
                                                className="bg-green-600 hover:bg-green-700 text-white p-2 rounded mr-2 transition duration-200"
                                                onClick={handleUpdateMachine}
                                            >
                                                Kaydet
                                            </button>
                                            <button
                                                className="bg-gray-600 hover:bg-gray-900 text-white p-2 rounded transition duration-200"
                                                onClick={() => setEditingMachine(null)}
                                            >
                                                İptal
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                className="bg-yellow-600 hover:bg-yellow-700 text-white p-2 rounded mr-2 transition duration-200"
                                                onClick={() => handleEditMachine(machine)}
                                            >
                                                Düzenle
                                            </button>
                                            {isAdmin && (
                                                <button
                                                    className="bg-red-600 hover:bg-red-700 text-white p-2 rounded transition duration-200"
                                                    onClick={() => handleDeleteMachine(machine.id)}
                                                >
                                                    Sil
                                                </button>
                                            )}
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination mt-4 flex justify-center items-center">
                    <button
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 1}
                        className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-l"
                    >
                        Önceki
                    </button>
                    <span className="mx-4 text-gray-400">Sayfa {page}</span>
                    <button
                        onClick={() => handlePageChange(page + 1)}
                        disabled={cncMachines.length < limit}
                        className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-r"
                    >
                        Sonraki
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ManageCNCMachines;