import React from 'react';
import { Link } from 'react-router-dom';

function ProgrammerDashboardMain() {
    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200 p-8">
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                
                {/* Add Traceability Kartı */}
                <Link to="/programmer-dashboard/add-traceability" className="bg-[#2f2f2f] p-6 shadow-md rounded-lg hover:bg-gray-900 transition duration-200 h-40">
                    <h2 className="text-2xl font-bold text-gray-100 mb-4">Ürün Ekleyin</h2>
                    <p className="text-gray-400">Projeler ve CNC operasyonları için ürün verilerini girin.</p>
                </Link>
                
                {/* Raporlar Kartı */}
                <Link to="/programmer-dashboard/upload-excel" className="bg-[#2f2f2f] p-6 shadow-md rounded-lg hover:bg-gray-900 transition duration-200 h-40">
                    <h2 className="text-2xl font-bold text-gray-100 mb-4">Excel</h2>
                    <p className="text-gray-400">Excel dosyasını yükleyin.</p>
                </Link>

                
            </div>
        </div>
    );
}

export default ProgrammerDashboardMain;
