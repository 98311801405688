import React from 'react';
import { Link } from 'react-router-dom';

function AdminDashboard() {
    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200 p-8">
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                
                {/* Kullanıcıları Yönet Kartı */}
                <Link to="/admin-dashboard/manage-users" className="bg-[#2f2f2f] p-6 shadow-md rounded-lg hover:bg-gray-900 transition duration-200 h-40">
                    <h2 className="text-2xl font-bold text-gray-100 mb-4">Kullanıcıları Yönet</h2>
                    <p className="text-gray-400">Sisteme kullanıcı ekleyin, düzenleyin veya kaldırın.</p>
                </Link>
                
                {/* Raporlar Kartı */}
                <Link to="/PSTAT" className="bg-[#2f2f2f] p-6 shadow-md rounded-lg hover:bg-gray-900 transition duration-200 h-40">
                    <h2 className="text-2xl font-bold text-gray-100 mb-4">Raporlar</h2>
                    <p className="text-gray-400">Raporları görüntüleyin ve oluşturun.</p>
                </Link>
                
                
            </div>
        </div>
    );
}

export default AdminDashboard;
