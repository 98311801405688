import React from 'react';
import { Link } from 'react-router-dom';

const Menu = ({ closeMenu }) => {
  const role = localStorage.getItem('role');

  return (
    <div className="w-full md:w-64 bg-[#171717] text-gray-200 h-full md:h-screen p-3 shadow-lg flex flex-col">
      <h2 className="text-2xl font-bold mb-6 text-center text-white">Menü</h2>
      <ul className="flex-1 space-y-2">
        {role === 'Admin' && (
          <li>
            <Link
              to="/admin-dashboard"
              onClick={closeMenu}
              className="text-lg flex items-center justify-center bg-[#171717] text-white py-2 rounded-lg border border-[#55576d] shadow-md transition duration-200 ease-in-out hover:bg-white hover:text-black"
            >
              Admin Sayfası
            </Link>
          </li>
        )}
        {(role === 'Admin' || role === 'Manager') && (
          <li>
            <Link
              to="/manager-dashboard"
              onClick={closeMenu}
              className="text-lg flex items-center justify-center bg-[#171717] text-white py-2 rounded-lg border border-[#55576d] shadow-md transition duration-200 ease-in-out hover:bg-white hover:text-black"
            >
              Yönetici Sayfası
            </Link>
          </li>
        )}
        {(role === 'Admin' || role === 'Manager' || role === 'Programmer') && (
          <>
            <li>
              <Link
                to="/programmer-dashboard"
                onClick={closeMenu}
                className="text-lg flex items-center justify-center bg-[#171717] text-white py-2 rounded-lg border border-[#55576d] shadow-md transition duration-200 ease-in-out hover:bg-white hover:text-black"
              >
                Ürün Ekle
              </Link>
            </li>
            <li>
              <Link
                to="/PSTAT"
                onClick={closeMenu}
                className="text-lg flex items-center justify-center bg-[#171717] text-white py-2 rounded-lg border border-[#55576d] shadow-md transition duration-200 ease-in-out hover:bg-white hover:text-black"
              >
                Ürün Durumunu Güncelle
              </Link>
            </li>
            <li>
              <Link
                to="/add-project-id"
                onClick={closeMenu}
                className="text-lg flex items-center justify-center bg-[#171717] text-white py-2 rounded-lg border border-[#55576d] shadow-md transition duration-200 ease-in-out hover:bg-white hover:text-black"
              >
                Proje Ekle
              </Link>
            </li>
            <li>
              <Link
                to="/manage-cnc-codes"
                onClick={closeMenu}
                className="text-lg flex items-center justify-center bg-[#171717] text-white py-2 rounded-lg border border-[#55576d] shadow-md transition duration-200 ease-in-out hover:bg-white hover:text-black"
              >
                CNC Ekle
              </Link>
            </li>
            <li>
              <Link
                to="/status-management"
                onClick={closeMenu}
                className="text-lg flex items-center justify-center bg-[#171717] text-white py-2 rounded-lg border border-[#55576d] shadow-md transition duration-200 ease-in-out hover:bg-white hover:text-black"
              >
                Durum Yönetimi
              </Link>
            </li>
            <li>
              <Link
                to="/suppliers-management"
                onClick={closeMenu}
                className="text-lg flex items-center justify-center bg-[#171717] text-white py-2 rounded-lg border border-[#55576d] shadow-md transition duration-200 ease-in-out hover:bg-white hover:text-black"
              >
                Tedarikçi Yönetimi
              </Link>
            </li>
          </>
        )}
      </ul>
      <ul className="mt-auto">
        <li>
          <Link
            to="/logout"
            onClick={closeMenu}
            className="text-lg flex items-center justify-center bg-red-600 text-white py-2 rounded-lg border border-transparent shadow-md transition duration-200 ease-in-out hover:bg-red-700 hover:border-red-800"
          >
            Çıkış Yap
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;