import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StatusesDashboard = () => {
    const [statuses, setStatuses] = useState([]);
    const [newStatus, setNewStatus] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [editingName, setEditingName] = useState('');
    const [message, setMessage] = useState({ text: '', type: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchStatuses();
    }, []);

    const fetchStatuses = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://soldrena.com/api/statuses', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setStatuses(response.data);
        } catch (error) {
            console.error('Error fetching statuses:', error);
            setMessage({ text: 'Failed to fetch statuses', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newStatus.trim()) {
            setMessage({ text: 'Status name cannot be empty', type: 'error' });
            return;
        }
        setLoading(true);
        try {
            await axios.post('https://soldrena.com/api/statuses', { status_name: newStatus }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessage({ text: 'Status added successfully', type: 'success' });
            setNewStatus('');
            fetchStatuses();
        } catch (error) {
            console.error('Error adding status:', error);
            setMessage({ text: 'Failed to add status', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (id, name) => {
        setEditingId(id);
        setEditingName(name);
    };

    const handleUpdate = async (id) => {
        if (!editingName.trim()) {
            setMessage({ text: 'Status name cannot be empty', type: 'error' });
            return;
        }
        setLoading(true);
        try {
            await axios.put(`https://soldrena.com/api/statuses/${id}`, { status_name: editingName }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessage({ text: 'Status updated successfully', type: 'success' });
            setEditingId(null);
            fetchStatuses();
        } catch (error) {
            console.error('Error updating status:', error);
            setMessage({ text: 'Failed to update status', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this status?')) {
            setLoading(true);
            try {
                await axios.delete(`https://soldrena.com/api/statuses/${id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setMessage({ text: 'Status deleted successfully', type: 'success' });
                fetchStatuses();
            } catch (error) {
                console.error('Error deleting status:', error);
                setMessage({ text: 'Failed to delete status', type: 'error' });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200">
            <div className="container mx-auto p-4">
                <h2 className="text-3xl font-semibold mb-6 text-gray-100">Statuses Dashboard</h2>
                {message.text && (
                    <p className={`mb-4 ${message.type === 'error' ? 'text-red-500' : 'text-green-500'}`}>
                        {message.text}
                    </p>
                )}
                <form onSubmit={handleSubmit} className="mb-8">
                    <div className="flex items-center">
                        <input
                            type="text"
                            value={newStatus}
                            onChange={(e) => setNewStatus(e.target.value)}
                            placeholder="Enter new status name"
                            className="flex-grow mr-2 p-2 bg-[#2f2f2f] border border-gray-600 rounded"
                        />
                        <button
                            type="submit"
                            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            disabled={loading}
                        >
                            Add Status
                        </button>
                    </div>
                </form>

                <table className="min-w-full bg-[#2f2f2f] shadow-md rounded overflow-hidden">
                    <thead className="bg-[#2f2f2f]">
                        <tr>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">ID</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Status Name</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Created At</th>
                            <th className="py-3 px-4 border-b border-gray-600 text-center text-gray-400 font-semibold">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {statuses.map((status) => (
                            <tr key={status.id} className="hover:bg-gray-900 transition duration-200">
                                <td className="py-3 px-4 border-b border-gray-600">{status.id}</td>
                                <td className="py-3 px-4 border-b border-gray-600">
                                    {editingId === status.id ? (
                                        <input
                                            type="text"
                                            value={editingName}
                                            onChange={(e) => setEditingName(e.target.value)}
                                            className="w-full p-1 bg-[#2f2f2f] border border-gray-600 rounded"
                                        />
                                    ) : (
                                        status.status_name
                                    )}
                                </td>
                                <td className="py-3 px-4 border-b border-gray-600">{new Date(status.created_at).toLocaleString()}</td>
                                <td className="py-3 px-4 border-b border-gray-600 text-center">
                                    {editingId === status.id ? (
                                        <>
                                            <button
                                                onClick={() => handleUpdate(status.id)}
                                                className="bg-green-600 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
                                                disabled={loading}
                                            >
                                                Save
                                            </button>
                                            <button
                                                onClick={() => setEditingId(null)}
                                                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded"
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={() => handleEdit(status.id, status.status_name)}
                                                className="bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => handleDelete(status.id)}
                                                className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                            >
                                                Delete
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default StatusesDashboard;