import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPage = () => {
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ username: '', password: '', role: '', fullName: '', email: '' });
    const [editingUser, setEditingUser] = useState(null);
    const [message, setMessage] = useState('');
    const role = localStorage.getItem('role');  // Yerel depolamadan rolü al

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://soldrena.com/api/users', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Kullanıcılar getirilirken hata oluştu:', error);
            setUsers([]);
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://soldrena.com/api/users/add', newUser, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessage(response.data.message);
            setNewUser({ username: '', password: '', role: '', fullName: '', email: '' });
            fetchUsers();
        } catch (error) {
            console.error('Kullanıcı eklenirken hata oluştu:', error);
            setMessage('Kullanıcı eklenemedi');
        }
    };

    const handleEditUser = (user) => {
        setEditingUser({ ...user });
    };

    const handleUpdateUser = async () => {
        if (!editingUser.username || !editingUser.role || !editingUser.full_name) {
            setMessage('Lütfen tüm gerekli alanları doldurun.');
            return;
        }

        try {
            await axios.put(`https://soldrena.com/api/users/update/${editingUser.id}`, editingUser, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessage('Kullanıcı başarıyla güncellendi!');
            setEditingUser(null);
            fetchUsers();
        } catch (error) {
            console.error('Kullanıcı güncellenirken hata oluştu:', error);
            setMessage('Kullanıcı güncellenemedi');
        }
    };

    const handleCancelEdit = () => {
        setEditingUser(null);
    };

    const handleDeleteUser = async (id) => {
        try {
            await axios.delete(`https://soldrena.com/api/users/delete/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            fetchUsers();
        } catch (error) {
            console.error('Kullanıcı silinirken hata oluştu:', error);
        }
    };

    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200">
            <div className="flex-1 bg-[#212121] p-2 sm:p-4 md:p-8 overflow-y-auto">
                <h2 className="text-2xl sm:text-3xl font-bold mb-4 md:mb-8 text-gray-100">Yönetici Paneli</h2>

                <div className="bg-[#2f2f2f] p-3 sm:p-4 md:p-6 shadow-md rounded mb-4 md:mb-8">
                    <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-100">Yeni Kullanıcı Ekle</h3>
                    <form onSubmit={handleAddUser} className="grid grid-cols-1 gap-4">
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="text"
                            placeholder="Kullanıcı Adı"
                            value={newUser.username}
                            onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                            required
                            autoComplete="off"
                        />
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="password"
                            placeholder="Şifre"
                            value={newUser.password}
                            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                            required
                            autoComplete="new-password"
                        />
                        <select
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            value={newUser.role}
                            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                            required
                        >
                            <option value="" disabled>Rol Seçin</option>
                            <option value="Admin">Yönetici</option>
                            <option value="Manager">Müdür</option>
                            <option value="Programmer">Programcı</option>
                            <option value="Operator">Operatör</option>
                        </select>
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="text"
                            placeholder="Tam Ad"
                            value={newUser.fullName}
                            onChange={(e) => setNewUser({ ...newUser, fullName: e.target.value })}
                            required
                        />
                        <input
                            className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                            type="email"
                            placeholder="E-posta (İsteğe bağlı)"
                            value={newUser.email}
                            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                        />
                        <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 mt-4">
                            Kullanıcı Ekle
                        </button>
                    </form>
                </div>

                <div className="bg-[#2f2f2f] p-3 sm:p-4 md:p-6 shadow-md rounded">
                    <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-100">Kullanıcı Listesi</h3>
                    {message && <p className="text-green-500 mb-4">{message}</p>}
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-[#2f2f2f] border border-gray-600 rounded overflow-hidden">
                            <thead className="bg-[#2f2f2f]">
                                <tr>
                                    <th className="py-2 md:py-3 px-2 md:px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Kullanıcı Adı</th>
                                    <th className="py-2 md:py-3 px-2 md:px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Rol</th>
                                    <th className="py-2 md:py-3 px-2 md:px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">Tam Ad</th>
                                    <th className="py-2 md:py-3 px-2 md:px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">E-posta</th>
                                    <th className="py-2 md:py-3 px-2 md:px-4 border-b border-gray-600 text-center text-gray-400 font-semibold">İşlemler</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user) => (
                                    <tr key={user.id} className="hover:bg-gray-900 text-gray-200 transition duration-200">
                                        <td className="py-2 md:py-3 px-2 md:px-4 border-b border-gray-600">
                                            {editingUser?.id === user.id ? (
                                                <input
                                                    type="text"
                                                    className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                                    value={editingUser.username}
                                                    onChange={(e) => setEditingUser({ ...editingUser, username: e.target.value })}
                                                />
                                            ) : (
                                                <span>{user.username}</span>
                                            )}
                                        </td>
                                        <td className="py-2 md:py-3 px-2 md:px-4 border-b border-gray-600">
                                            {editingUser?.id === user.id ? (
                                                <select
                                                    className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                                    value={editingUser.role}
                                                    onChange={(e) => setEditingUser({ ...editingUser, role: e.target.value })}
                                                >
                                                    <option value="" disabled>Rol Seçin</option>
                                                    <option value="Admin">Yönetici</option>
                                                    <option value="Manager">Müdür</option>
                                                    <option value="Programmer">Programcı</option>
                                                    <option value="Operator">Operatör</option>
                                                </select>
                                            ) : (
                                                <span>{user.role}</span>
                                            )}
                                        </td>
                                        <td className="py-2 md:py-3 px-2 md:px-4 border-b border-gray-600">
                                            {editingUser?.id === user.id ? (
                                                <input
                                                    type="text"
                                                    className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                                    value={editingUser.full_name}
                                                    onChange={(e) => setEditingUser({ ...editingUser, full_name: e.target.value })}
                                                />
                                            ) : (
                                                <span>{user.full_name}</span>
                                            )}
                                        </td>
                                        <td className="py-2 md:py-3 px-2 md:px-4 border-b border-gray-600">
                                            {editingUser?.id === user.id ? (
                                                <input
                                                    type="email"
                                                    className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                                                    value={editingUser.email}
                                                    onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
                                                />
                                            ) : (
                                                <span>{user.email}</span>
                                            )}
                                        </td>
                                        <td className="py-2 md:py-3 px-2 md:px-4 border-b border-gray-600 text-center">
                                            {editingUser?.id === user.id ? (
                                                <>
                                                    <button
                                                        className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 mr-2 mb-2 sm:mb-0"
                                                        onClick={handleUpdateUser}
                                                    >
                                                        Kaydet
                                                    </button>
                                                    <button
                                                        className="bg-gray-600 hover:bg-gray-900 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200"
                                                        onClick={handleCancelEdit}
                                                    >
                                                        İptal
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        className="bg-yellow-600 hover:bg-yellow-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 mr-2 mb-2 sm:mb-0"
                                                        onClick={() => handleEditUser(user)}
                                                    >
                                                        Düzenle
                                                    </button>
                                                    
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPage;