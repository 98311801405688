import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Html5Qrcode } from 'html5-qrcode';

const QRCodeScanner = () => {
  const [productId, setProductId] = useState('');
  const [scannedResult, setScannedResult] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [availableDevices, setAvailableDevices] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [notes, setNotes] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [audioReady, setAudioReady] = useState(false);

  useEffect(() => {
    const successSound = document.getElementById('successSound');
    successSound.load();
    successSound.addEventListener('canplaythrough', () => setAudioReady(true));
    return () => successSound.removeEventListener('canplaythrough', () => setAudioReady(true));
  }, []);

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    const fetchSuppliersStatusesProjects = async () => {
      try {
        const token = localStorage.getItem('token');
        const [supplierResponse, statusResponse, projectResponse] = await Promise.all([
          axios.get('https://soldrena.com/api/qr-codes/suppliers', {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get('https://soldrena.com/api/qr-codes/statuses', {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get('https://soldrena.com/api/qr-codes/projects', {
            headers: { Authorization: `Bearer ${token}` }
          })
        ]);
        setSuppliers(supplierResponse.data);
        setStatusOptions(statusResponse.data);
        setProjects(projectResponse.data);
      } catch (error) {
        console.error('Error fetching suppliers, statuses, or projects:', error);
        setErrorMessage('Tedarikçiler, durumlar veya projeler alınırken bir hata oluştu.');
      }
    };
    fetchSuppliersStatusesProjects();
  }, []);

  useEffect(() => {
    Html5Qrcode.getCameras().then((devices) => {
      if (devices && devices.length) {
        setAvailableDevices(devices);
        const backCamera = devices.find(device => device.label.toLowerCase().includes('back')) || devices[0];
        setSelectedCamera(backCamera.id);
      }
    }).catch(err => console.error('Error getting cameras:', err));
  }, []);

  const startCamera = () => {
    setIsScanning(true);
    const qrScanner = new Html5Qrcode('qr-reader');
    const config = { fps: 10, qrbox: 250 };
    qrScanner.start(selectedCamera, config, (decodedText) => {
      setProductId(decodedText);
      setIsScanning(false);
      if (audioReady) {
        const successSound = document.getElementById('successSound');
        successSound.play();
      }
      qrScanner.stop();
    }).catch(err => {
      console.error('Error scanning QR Code:', err);
      setIsScanning(false);
    });
  };
  const fetchProductMatches = async (value) => {
    setProductId(value);
    if (value.length >= 3 && selectedProject) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Token bulunamadı, lütfen tekrar giriş yapın.');
        return;
      }
      try {
        const response = await axios.get(`https://soldrena.com/api/traceability/search?query=${value}&project_id=${selectedProject}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSuggestions(response.data);
        setShowSuggestions(true);
      } catch (error) {
        console.error('Ürün eşleşmeleri alınırken hata oluştu:', error);
        setSuggestions([]);
        setShowSuggestions(false);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };
  
  const handleSubmit = async () => {
    if (!productId || !selectedProject) {
      setErrorMessage('Ürün kodu ve Proje gereklidir.');
      return;
    }
    try {
      const response = await axios.post('https://soldrena.com/api/qr-codes/scan', {
        productId,
        supplierId: selectedSupplier,
        statusId: selectedStatus,
        projectId: selectedProject,
        notes
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setSuccessMessage(response.data.message);
      setErrorMessage('');
    } catch (error) {
      console.error('Error submitting data:', error);
      setErrorMessage(error.response?.data?.error || 'Veri gönderilemedi, lütfen tekrar deneyin.');
      setSuccessMessage('');
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setProductId(suggestion.cam_code);
    setShowSuggestions(false);
  };

  return (
    <div className="scanner flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-lg p-4 md:p-8"> {/* Added padding for larger screens */}
        <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Ürün Durumu Güncelleme</h1>
        <h2 className="text-lg font-medium italic text-gray-800 mb-8 text-center leading-relaxed">
  Lütfen bir proje kodu seçin. QR kod okutacaksanız, arka kamerayı seçtikten sonra <strong>QR Kod Tarat</strong> butonuna basın. 
  Daha sonra, ürünün durumunu seçin (örneğin: <em>CAM Başladı</em>). Eğer ürün dışarıya gönderilecekse, tedarikçiyi seçmeyi unutmayın.
</h2>

        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            {errorMessage}
          </div>
        )}
        {successMessage && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
            {successMessage}
          </div>
        )}

        {/* Project Dropdown */}
        <div className="mb-4">
          <label className="block text-gray-700 text-lg font-semibold mb-2">Proje Seç:</label>
          <select
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600"
          >
            <option value="">Proje Seç</option>
            {projects.map(project => (
              <option key={project.id} value={project.id}>
                {project.project_id} - {project.project_name}
              </option>
            ))}
          </select>
        </div>

        {/* Camera Dropdown */}
        <div className="mb-4">
          <label className="block text-gray-700 text-lg font-semibold mb-2">Kamera Seç:</label>
          <select
            value={selectedCamera}
            onChange={(e) => setSelectedCamera(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600"
          >
            {availableDevices.map((device) => (
              <option key={device.id} value={device.id}>
                {device.label || `Kamera ${device.id}`}
              </option>
            ))}
          </select>
        </div>

        {/* Product Code Input */}
        <div className="mb-4 relative">
          <label className="block text-gray-700 text-lg font-semibold mb-2">Ürün Kodu (cam_code):</label>
          <input
            type="text"
            value={productId}
            onChange={(e) => fetchProductMatches(e.target.value)}
            placeholder="Yazın veya QR kodu tarayın"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600"
          />
          {showSuggestions && suggestions.length > 0 && (
            <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-40 overflow-y-auto">
              {suggestions.map((suggestion) => (
                <li
                  key={suggestion.cam_code}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="px-4 py-2 cursor-pointer hover:bg-indigo-100"
                >
                  {suggestion.cam_code}
                </li>
              ))}
            </ul>
          )}
          <button
            onClick={startCamera}
            className="mt-3 w-full py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors"
          >
            {isScanning ? "Tarama Yapılıyor..." : "QR Kod Tarat"}
          </button>
        </div>

        {/* Status Dropdown */}
        <div className="mb-4">
          <label className="block text-gray-700 text-lg font-semibold mb-2">Durum:</label>
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600"
          >
            <option value="">Durum Seç</option>
            {statusOptions.map(status => (
              <option key={status.id} value={status.id}>
                {status.status_name}
              </option>
            ))}
          </select>
        </div>

        {/* Supplier Dropdown */}
        <div className="mb-4">
          <label className="block text-gray-700 text-lg font-semibold mb-2">Tedarikçi:</label>
          <select
            value={selectedSupplier}
            onChange={(e) => setSelectedSupplier(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600"
          >
            <option value="">Tedarikçi Seç</option>
            {suppliers.map(supplier => (
              <option key={supplier.id} value={supplier.id}>
                {supplier.supplier_name}
              </option>
            ))}
          </select>
        </div>

        {/* Notes Field */}
        <div className="mb-4">
          <label className="block text-gray-700 text-lg font-semibold mb-2">Notlar:</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Herhangi bir not ekleyin..."
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600"
          />
        </div>

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          className="w-full py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
        >
          Gönder
        </button>

        {/* QR Reader Element */}
        <div id="qr-reader" className="mt-6" style={{ width: '100%' }}></div>

        {/* Success Sound */}
        <audio id="successSound" src="/Beep_Short.mp3" preload="auto"></audio>
      </div>
    </div>
  );
};

export default QRCodeScanner;
