import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear the token and role from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('role');

        // Redirect to login
        navigate('/login');
    }, [navigate]);

    return null;
}

export default Logout;
