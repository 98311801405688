import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManagerDashboard = () => {
    const [traceabilityData, setTraceabilityData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [operators, setOperators] = useState([]);
    const [cncCodes, setCncCodes] = useState([]);
    const [message, setMessage] = useState({ text: '', type: '' });
    const [loading, setLoading] = useState(false);
    const [editingRowId, setEditingRowId] = useState(null);
    const [filters, setFilters] = useState({
        project: '',
        operator: '',
        cnc: '',
        startTime: '',
        endTime: '',
    });
    const [pagination, setPagination] = useState({ limit: 10, offset: 0, total: 0 });
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const userRole = localStorage.getItem('role');

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        fetchDropdownData();
        fetchTraceabilityData();
    }, [filters, pagination.offset, sortConfig]);

    const fetchDropdownData = async () => {
        try {
            const [projectsResponse, operatorsResponse, cncCodesResponse] = await Promise.all([
                axios.get('https://soldrena.com/api/traceability/projects', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }),
                axios.get('https://soldrena.com/api/traceability/operators', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }),
                axios.get('https://soldrena.com/api/traceability/cnc-codes', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }),
            ]);

            setProjects(projectsResponse.data);
            setOperators(operatorsResponse.data);
            setCncCodes(cncCodesResponse.data);
        } catch (error) {
            console.error('Açılır menü verileri alınırken hata oluştu:', error);
        }
    };

    const fetchTraceabilityData = async () => {
        try {
            const response = await axios.get('https://soldrena.com/api/traceability', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: {
                    ...filters,
                    limit: pagination.limit,
                    offset: pagination.offset,
                    sortKey: sortConfig.key,
                    sortDirection: sortConfig.direction,
                },
            });
            setTraceabilityData(Array.isArray(response.data) ? response.data : []);
            setPagination((prev) => ({ ...prev, total: response.data.length }));
        } catch (error) {
            console.error('İzlenebilirlik verileri alınırken hata oluştu:', error);
            setTraceabilityData([]);
        }
    };

    const handleUpdate = async (id, updatedData) => {
        setLoading(true);
        try {
            // Güncelleme isteği için yükü hazırlayın
            const payload = {
                ...updatedData,
                part_count: updatedData.part_count || 1,
            };

            await axios.put(`https://soldrena.com/api/traceability/update/${id}`, payload, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });

            setMessage({ text: 'Veri başarıyla güncellendi', type: 'success' });
            setEditingRowId(null); // Düzenleme modundan çık
            fetchTraceabilityData(); // Verileri yenile
        } catch (error) {
            console.error('Veri güncellenirken hata oluştu:', error);
            setMessage({ text: 'Veri güncellenemedi', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            await axios.delete(`https://soldrena.com/api/traceability/delete/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessage({ text: 'Veri başarıyla silindi', type: 'success' });
            fetchTraceabilityData(); // Verileri yenile
        } catch (error) {
            console.error('Veri silinirken hata oluştu:', error);
            setMessage({ text: 'Veri silinemedi', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (id, field, value) => {
        setTraceabilityData(traceabilityData.map(data =>
            data.id === id ? { ...data, [field]: value } : data
        ));
    };

    const toggleEdit = (id) => {
        setEditingRowId(id);
    };

    const cancelEdit = () => {
        setEditingRowId(null);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleExportToExcel = async () => {
        try {
            const response = await axios.get('https://soldrena.com/api/traceability/export', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: filters,
                responseType: 'blob',
            });
            
            // Check if the response is valid
            if (response.data.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                throw new Error('Invalid file format received');
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'izlenebilirlik_verileri.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Veri dışa aktarılırken hata oluştu:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            }
            setMessage({ text: `Excel dosyası oluşturulurken bir hata oluştu: ${error.message}`, type: 'error' });
        }
    };

    const handlePageChange = (newOffset) => {
        setPagination((prev) => ({ ...prev, offset: newOffset }));
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) return '';
        return sortConfig.direction === 'asc' ? '▲' : '▼';
    };

    const renderFilters = () => (
        <div className={`mb-4 ${isMobile ? 'flex flex-col space-y-2' : 'flex space-x-2'}`}>
            <select
                name="project"
                value={filters.project}
                onChange={handleFilterChange}
                className="border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
            >
                <option value="">Tüm Projeler</option>
                {projects.map((project) => (
                    <option key={project.id} value={project.project_id}>
                        {project.project_id} - {project.project_name}
                    </option>
                ))}
            </select>
            <select
                name="operator"
                value={filters.operator}
                onChange={handleFilterChange}
                className="border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
            >
                <option value="">Tüm Operatörler</option>
                {operators.map((operator) => (
                    <option key={operator.id} value={operator.full_name}>
                        {operator.full_name}
                    </option>
                ))}
            </select>
            <select
                name="cnc"
                value={filters.cnc}
                onChange={handleFilterChange}
                className="border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
            >
                <option value="">Tüm CNC Kodları</option>
                {cncCodes.map((cnc) => (
                    <option key={cnc.id} value={cnc.cnc_code}>
                        {cnc.cnc_code}
                    </option>
                ))}
            </select>
            <input
                type="date"
                name="startTime"
                placeholder="Başlangıç Zamanı"
                value={filters.startTime}
                onChange={handleFilterChange}
                className="border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
            />
            <input
                type="date"
                name="endTime"
                placeholder="Bitiş Zamanı"
                value={filters.endTime}
                onChange={handleFilterChange}
                className="border border-gray-600 rounded w-full py-2 px-3 bg-[#2f2f2f] text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
            />
            <button
                onClick={handleExportToExcel}
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200"
            >
                Excel'e Aktar
            </button>
        </div>
    );

    const renderTableHeader = () => (
        <thead className="bg-[#2f2f2f]">
            <tr>
                {['Project', 'Ürün Kodu', 'Operator', 'CNC Code', 'Price', 'Actions'].map((header, index) => (
                    <th key={index} className="py-3 px-4 border-b border-gray-600 text-left text-gray-400 font-semibold">
                        {header}
                    </th>
                ))}
            </tr>
        </thead>
    );

    const renderTableRow = (data) => (
        <tr key={data.id} className="hover:bg-gray-900 text-gray-200 transition duration-200">
            <td className="py-3 px-4 border-b border-gray-600">
                {editingRowId === data.id ? (
                    <select
                        value={data.project_id}
                        onChange={(e) => handleInputChange(data.id, 'project_id', e.target.value)}
                        className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                    >
                        {projects.map((project) => (
                            <option key={project.id} value={project.project_id}>
                                {project.project_id} - {project.project_name}
                            </option>
                        ))}
                    </select>
                ) : (
                    data.project_code
                )}
            </td>
            <td className="py-3 px-4 border-b border-gray-600">
                {editingRowId === data.id ? (
                    <input
                        type="text"
                        value={data.cam_code}
                        onChange={(e) => handleInputChange(data.id, 'cam_code', e.target.value)}
                        className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                    />
                ) : (
                    data.cam_code
                )}
            </td>
            <td className="py-3 px-4 border-b border-gray-600">
                {editingRowId === data.id ? (
                    <select
                        value={data.operator_id}
                        onChange={(e) => handleInputChange(data.id, 'operator_id', e.target.value)}
                        className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                    >
                        {operators.map((operator) => (
                            <option key={operator.id} value={operator.id}>
                                {operator.full_name}
                            </option>
                        ))}
                    </select>
                ) : (
                    data.operator_name
                )}
            </td>
            <td className="py-3 px-4 border-b border-gray-600">
                {editingRowId === data.id ? (
                    <select
                        value={data.cnc_id}
                        onChange={(e) => handleInputChange(data.id, 'cnc_id', e.target.value)}
                        className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                    >
                        {cncCodes.map((cnc) => (
                            <option key={cnc.id} value={cnc.id}>
                                {cnc.cnc_code}
                            </option>
                        ))}
                    </select>
                ) : (
                    data.cnc_code
                )}
            </td>
            <td className="py-3 px-4 border-b border-gray-600">
                {editingRowId === data.id ? (
                    <div className="flex items-center">
                        <span>₺</span>
                        <input
                            type="number"
                            value={data.price}
                            onChange={(e) => handleInputChange(data.id, 'price', e.target.value)}
                            className="border border-gray-600 rounded w-full py-2 px-3 bg-gray-900 text-gray-200 leading-tight focus:outline-none focus:border-blue-500"
                        />
                    </div>
                ) : (
                    <span>₺{data.price}</span>
                )}
            </td>
            <td className="py-3 px-4 border-b border-gray-600 text-center">
                {editingRowId === data.id ? (
                    <>
                        <button
                            onClick={() => handleUpdate(data.id, data)}
                            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200"
                            disabled={loading}
                        >
                            Kaydet
                        </button>
                        <button
                            onClick={cancelEdit}
                            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200 ml-2"
                        >
                            İptal
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            onClick={() => toggleEdit(data.id)}
                            className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200"
                        >
                            Düzenle
                        </button>
                        {userRole === 'Admin' && (
                            <button
                                onClick={() => handleDelete(data.id)}
                                className="bg-red-600 hover:bg-red-700 text-white font-semibold py-1 px-3 rounded shadow-md transition duration-200 ml-2"
                            >
                                Sil
                            </button>
                        )}
                    </>
                )}
            </td>
        </tr>
    );

    const renderMobileCard = (data) => (
        <div key={data.id} className="bg-[#2f2f2f] p-4 mb-4 rounded-lg">
            {editingRowId === data.id ? (
                <>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-1">Project:</label>
                        <select
                            value={data.project_id}
                            onChange={(e) => handleInputChange(data.id, 'project_id', e.target.value)}
                            className="w-full p-2 bg-gray-900 text-gray-200 rounded border border-gray-600"
                        >
                            {projects.map((project) => (
                                <option key={project.id} value={project.project_id}>
                                    {project.project_id} - {project.project_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-1">Ürün Kodu:</label>
                        <input
                            type="text"
                            value={data.cam_code}
                            onChange={(e) => handleInputChange(data.id, 'cam_code', e.target.value)}
                            className="w-full p-2 bg-gray-900 text-gray-200 rounded border border-gray-600"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-1">Operator:</label>
                        <select
                            value={data.operator_id}
                            onChange={(e) => handleInputChange(data.id, 'operator_id', e.target.value)}
                            className="w-full p-2 bg-gray-900 text-gray-200 rounded border border-gray-600"
                        >
                            {operators.map((operator) => (
                                <option key={operator.id} value={operator.id}>
                                    {operator.full_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-1">CNC Code:</label>
                        <select
                            value={data.cnc_id}
                            onChange={(e) => handleInputChange(data.id, 'cnc_id', e.target.value)}
                            className="w-full p-2 bg-gray-900 text-gray-200 rounded border border-gray-600"
                        >
                            {cncCodes.map((cnc) => (
                                <option key={cnc.id} value={cnc.id}>
                                    {cnc.cnc_code}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-1">Price:</label>
                        <div className="flex items-center">
                            <span className="mr-2">₺</span>
                            <input
                                type="number"
                                value={data.price}
                                onChange={(e) => handleInputChange(data.id, 'price', e.target.value)}
                                className="w-full p-2 bg-gray-900 text-gray-200 rounded border border-gray-600"
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <p><strong>Project:</strong> {data.project_code}</p>
                    <p><strong>Ürün Kodu:</strong> {data.cam_code}</p>
                    <p><strong>Operator:</strong> {data.operator_name}</p>
                    <p><strong>CNC Code:</strong> {data.cnc_code}</p>
                    <p><strong>Price:</strong> ₺{data.price}</p>
                </>
            )}
            <div className="mt-2">
                {editingRowId === data.id ? (
                    <>
                        <button
                            onClick={() => handleUpdate(data.id, data)}
                            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 w-full mb-2"
                            disabled={loading}
                        >
                            Kaydet
                        </button>
                        <button
                            onClick={cancelEdit}
                            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 w-full"
                        >
                            İptal
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            onClick={() => toggleEdit(data.id)}
                            className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 w-full mb-2"
                        >
                            Düzenle
                        </button>
                        {userRole === 'Admin' && (
                            <button
                                onClick={() => handleDelete(data.id)}
                                className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200 w-full"
                            >
                                Sil
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );

    return (
        <div className="flex min-h-screen bg-[#212121] text-gray-200">
            <div className="container mx-auto p-4">
                <h2 className="text-3xl font-semibold mb-6 text-gray-100">Ürünler</h2>
                {message.text && (
                    <p className={`mb-4 ${message.type === 'error' ? 'text-red-500' : 'text-green-500'}`}>
                        {message.text}
                    </p>
                )}

                {isMobile ? (
                    <button
                        onClick={() => setShowFilters(!showFilters)}
                        className="w-full mb-4 bg-blue-600 text-white py-2 px-4 rounded"
                    >
                        {showFilters ? 'Hide Filters' : 'Show Filters'}
                    </button>
                ) : null}

                {(isMobile && showFilters) || !isMobile ? renderFilters() : null}

                {isMobile ? (
                    traceabilityData.map(renderMobileCard)
                ) : (
                    <table className="min-w-full bg-[#2f2f2f] shadow-md rounded overflow-hidden">
                        {renderTableHeader()}
                        <tbody>
                            {traceabilityData.map(renderTableRow)}
                        </tbody>
                    </table>
                )}

                {/* Pagination controls */}
                <div className="flex justify-between mt-4">
                    <button
                        onClick={() => handlePageChange(Math.max(pagination.offset - pagination.limit, 0))}
                        className="bg-gray-600 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200"
                        disabled={pagination.offset === 0}
                    >
                        Önceki
                    </button>
                    <button
                        onClick={() => handlePageChange(pagination.offset + pagination.limit)}
                        className="bg-gray-600 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-200"
                        disabled={traceabilityData.length < pagination.limit}
                    >
                        Sonraki
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ManagerDashboard;
                             