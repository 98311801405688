// frontend/src/components/ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = ({ children, roles = [] }) => {
    const [isAllowed, setIsAllowed] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const verifyRole = async () => {
            if (!token) {
                setIsAllowed(false);
                return;
            }

            try {
                const response = await axios.get('https://soldrena.com/api/auth/verify-role', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const userRole = response.data.role;

                // Check if the user role is allowed or if the user is an Admin
                if (roles.length && !roles.includes(userRole) && userRole !== 'Admin') {
                    setIsAllowed(false);
                } else {
                    setIsAllowed(true);
                }
            } catch (error) {
                console.error('Role verification failed:', error);
                setIsAllowed(false);
            }
        };

        verifyRole();
    }, [token, roles]);

    if (isAllowed === null) {
        return <div>Loading...</div>; // Show a loading state while verifying
    }

    return isAllowed ? children : <Navigate to="/Unauthorized" />;
};

export default ProtectedRoute;
